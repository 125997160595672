<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{title}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col class="pb-0" cols="auto">
              <v-menu
                ref="menuCofirmDate"
                v-model="menuCofirmDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                class="caption"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    class="caption"
                    placeholder="Tanggal"
                    prepend-inner-icon="mdi-calendar"
                    :value="form.confirmationDate && dateFormat(form.confirmationDate)"
                    :rules="rules.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.confirmationDate"
                  @input="menuCofirmDate = false"
                  :min="minDate"
                  :max="maxDate"
                  @change="handleDate"
                  no-title
                  scrollable
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pb-0" cols="auto">
              <v-text-field
                outlined
                dense
                ref="time"
                placeholder="Jam"
                v-mask="{'alias': 'time', rightAlign: false}"
                @input="setPickupDate"
                v-model="time"
                prepend-inner-icon="mdi-clock-time-four-outline"
                class="caption"
                :rules="[($event) => rules.time($event)]"
                :value="time && timeFormat(time)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-textarea
                no-resize
                outlined
                dense
                v-model="form.notes"
                rows="5"
                :counter="255"
                :rules="[(v) => rulesNotes(v, !form.confirm, 255, 'Alasan pembatalan')]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
          :loading="isLoading"
        >
          {{$_strings.common.YES}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  rulesNotes,
  getDateTimeTz,
  dateFormat,
  timeFormat,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.time = null;
        this.$refs.form.reset();
        this.form.notes = '';
      }
    },
  },
  computed: {
    title() {
      if (this.form.confirm) return 'Terima Pesanan';
      return 'Tolak Pesanan';
    },
    minDate() {
      return dayjs(this.order.pickupDate).format();
    },
    maxDate() {
      return dayjs(this.order.pickupDate).add(1, 'months').format();
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      confirmationType: '',
      order: {},
      menuCofirmDate: false,
      rules: {
        date: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Tanggal'),
        ],
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          return true;
        },
      },
      time: null,
      form: {
        confirm: false,
        notes: '',
        datetimeTz: this.getDateTimeTz(),
      },
    };
  },
  methods: {
    rulesNotes,
    getDateTimeTz,
    dateFormat,
    timeFormat,
    setPickupDate(time) {
      const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (this.form.confirmationDate && timePattern.test(time)) {
        const date = dayjs(this.form.confirmationDate).format('YYYY-MM-DD');
        const confirmationDate = dayjs(`${date} ${time}`).format();
        this.form.confirmationDate = confirmationDate;
      }
    },
    handleDate() {
      this.setPickupDate(this.time);
      this.$refs.time.focus();
    },
    showNotif() {
      if (this.form.confirm) return this.$dialog.notify.success('Pesanan berhasil diterima');
      return this.$dialog.notify.success('Pesanan berhasil ditolak');
    },
    async submit() {
      try {
        this.isLoading = true;
        const { id } = this.order;
        let valid = true;
        if (!this.form.confirm) valid = this.$refs.form.validate();
        if (!valid) return;
        await this.$_services.order.confirmation(id, this.form, this.confirmationType);
        this.$emit('fetchOrders');
        this.$emit('refreshDataOnPageUnconfirmed');
        this.showNotif();
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
