class RenderFunction {
  constructor(_this) {
    this._this = _this;
  }

  tableHeader = (type) => {
    switch (type) {
      case 'shipper': return [
        {
          text: this._this.$_strings.order.ORDER_NUMBER,
          value: 'orderNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.CREATE_DATE,
          value: 'orderDate',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.CUSTOMER_NAME,
          value: 'senderName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.common.ACTION,
          value: 'action',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ];
      case 'multipick': return [
        {
          text: this._this.$_strings.order.ORIGIN,
          value: 'originalLocation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.MULTIPICK,
          value: 'multiPick',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ];
      case 'multidrop': return [
        {
          text: this._this.$_strings.order.MULTIDROP,
          value: 'multiDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ];
      case 'vehicle': return [
        {
          text: this._this.$_strings.order.NO_GRUP_CARGO,
          value: 'cargoGroupNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.NO_CARGO,
          value: 'cargoNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.VEHICLE,
          value: 'transportName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.TRANSPORTER,
          value: 'companyTransport',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.VEHICLE_NUMBER,
          value: 'transportNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.DELIVERY_DATE_ORIGIN,
          value: 'pickupDate',
          width: '120px',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.ORIGIN,
          value: 'originalLocation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.ETA,
          value: 'estimateArrival',
          width: '110px',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.STATUS,
          value: 'status',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.common.ACTION,
          value: 'action',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ];
      case 'qty': return [
        {
          text: '',
          value: 'calculation',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.ORDER_NUMBER,
          value: 'orderNumber',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.KILO,
          value: 'tonnage',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.KUBIKASI,
          value: 'cubic',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this._this.$_strings.order.BOX,
          value: 'carton',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: '',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ];
      case 'confirmation': return [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.ORDER_NO,
          cellClass: 'clickable',
          value: 'groupTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.GROUP_TITLE_NUMBER,
          cellClass: 'clickable',
          value: 'cargoTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.DELIVERY_DATE,
          cellClass: 'clickable',
          value: 'pickupDate',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.ORIGIN,
          cellClass: 'clickable',
          value: 'originLocationName',
          width: '200px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.DESTINATION,
          cellClass: 'clickable',
          value: 'destinationLocationName',
          width: '200px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.TYPE_MODA,
          cellClass: 'clickable',
          value: 'transportModel',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.DESC_TARIF,
          cellClass: 'clickable',
          value: 'serviceType',
          width: '120px',
          class: 'white--text primary',
        },
        {
          text: this._this.$_strings.order.SHIPPER,
          cellClass: 'clickable',
          value: 'abbreviation',
          align: 'center',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.order.STATUS,
          cellClass: 'clickable',
          value: 'latestStatus',
          align: 'center',
          width: '200px',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this._this.$_strings.common.ACTION,
          value: 'action',
          width: '120px',
          class: 'white--text primary text-capitalize',
        },
      ];
      default: return [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary',
        },
        {
          text: this._this.$_strings.order.ORDER_NO,
          value: 'groupTitle',
          width: '120px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this._this.$_strings.order.DELIVERY_DATE,
          value: 'pickupDate',
          width: '120px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this._this.$_strings.order.ORIGIN,
          value: 'originLocationName',
          width: '200px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this._this.$_strings.order.DESTINATION,
          value: 'destinationLocationName',
          width: '200px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this._this.$_strings.order.DESC_TARIF,
          value: 'serviceType',
          width: '120px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this._this.$_strings.order.SHIPPER,
          value: 'shipperAbbreviation',
          align: 'center',
          width: '120px',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: 'Aksi',
          value: 'action',
          width: '200px',
          class: 'white--text primary',
        },
      ];
    }
  };

  get data() {
    return {
      componentCreated: false,
      expanded: [],
      pagination: {
        itemsPerPage: +this._this.$route.query.itemsPerPage || 10,
        page: +this._this.$route.query.page || 1,
        sortBy: this._this.$route.query.sort ? [this._this.$route.query.sort.split(',')[0]] : [],
        sortDesc: this._this.$route.query.sort
          ? [this._this.$route.query.sort.split(',')[1] === 'DESC']
          : [],
      },
      items: [],
      totalItems: 0,
      isLoading: false,
      historyPath: null,
      itemTemporary: {
        itemSelected: {},
        isShowDialogVehicle: false,
      },
      filters: {
        shipmentDateStart: this._this.$route.query.shipmentDateStart || '',
        shipmentDateEnd: this._this.$route.query.shipmentDateEnd || '',
        shipmentNumber: this._this.$route.query.search || '',
        // FILTER BY
        shipperId: this._this.$route.query.shipperId || '',
        transporterId: this._this.$route.query.transporterId || '',
        originId: this._this.$route.query.originId || '',
        destinationId: this._this.$route.query.destinationId || '',
        licensePlate: this._this.$route.query.licensePlate || '',
        latestStatus: this._this.$route.query.latestStatus || '',
        serviceType: this._this.$route.query.serviceType || '',
        isCalculated: '',
        hasQuantityDelivery: this._this.$route.query.hasQuantityDelivery || '',
        isPod: '',
      },
    };
  }
}

export default RenderFunction;
