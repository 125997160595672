<template>
  <v-card
    outlined
    tile
    elevation="2"
    min-height="250"
  >
    <v-card-text>
      <section>
        <h4>{{$_strings.order.PICK_UP_LOCATION}}</h4>
        <p>{{locationOriginName}}</p>
        <div v-if="form.multiPick.length">
          <p class="ma-0" v-for="(pick, index) in form.multiPick" :key="index">
            {{index + 1}} - {{locationPickName(pick.locationId)}}
          </p>
        </div>
      </section>
      <section class="mt-2">
        <h4>{{$_strings.order.LOCATION_IN_ACCEPTED}}</h4>
        <p>{{locationDestName}}</p>
        <div v-if="form.multiDrop.length">
          <p class="ma-0" v-for="(multidrop, index) in form.multiDrop" :key="index">
            {{index + 1}} - {{locationDropName(multidrop.locationId)}}</p>
        </div>
      </section>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    multipick: {
      type: Array,
      default: () => [],
    },
    multidrop: {
      type: Array,
      default: () => [],
    },
    itemsOrigin: {
      type: Array,
      default: () => [],
    },
    itemsDestination: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    locationOriginName() {
      const findLocation = this.itemsOrigin.find((l) => l.id === this.form.originLocationId);
      if (findLocation) return findLocation.name;
      return '-';
    },
    locationDestName() {
      const findLocation = this.itemsDestination.find((l) => l.id === this.form.destinationLocationId);
      if (findLocation) return findLocation.name;
      return '-';
    },
  },
  methods: {
    locationPickName(locationId) {
      const findLocation = this.multipick.find((l) => l.locationId === locationId);
      if (findLocation) return findLocation.name;
      return '-';
    },
    locationDropName(locationId) {
      const findLocation = this.multidrop.find((l) => l.locationId === locationId);
      if (findLocation) return findLocation.name;
      return '-';
    },
  },
};
</script>
