<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="700"
      scrollable
    >
      <v-card :loading="isLoading">
        <v-card-title class="pb-0 caption">
          <h3>{{$_strings.order.ASSIGN_3PL}}</h3>
          <v-row>
            <v-col cols="12" class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title class="caption">
          <v-row v-if="listTransporter.length > 0">
            <v-col class="pt-0 d-flex justify-center align-center" cols="12">
              <v-row>
                <v-col cols="6">
                  <v-checkbox v-model="checkContinueFromShipmentCargo" class="pa-0 ma-0" hide-details>
                    <template v-slot:label>
                      <div class="caption grey lighten-3 pa-1 rounded">{{$_strings.order.IS_AN_ADVANCED_ROUTE}}</div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="form.nextFromShipmentCargo"
                    item-text="cargoNumber"
                    item-value="cargoNumber"
                    dense
                    outlined
                    hide-details
                    flat
                    multiple
                    :disabled="!checkContinueFromShipmentCargo"
                    :items="itemsCargo.filter((item) => item.cargoNumber !== shipment.cargoNumber)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pt-0 d-flex justify-center align-center" cols="12">
              <v-row>
                <v-col class="pt-0 ma-0" cols="6">
                  <v-checkbox v-model="checkGroupToShipment" hide-details>
                    <template v-slot:label>
                      <div class="caption grey lighten-3 pa-1 rounded">{{$_strings.order.MAKE_CARGO_GROUP}}</div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="form.groupToShipmentCargo"  :disabled="!checkGroupToShipment" dense outlined hide-details></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pt-0 d-flex justify-center align-center" cols="12">
              <v-row>
                <v-col class="pt-0 ma-0" cols="6">
                  <v-checkbox v-model="form.isLumpSum" hide-details>
                    <template v-slot:label>
                      <div class="caption grey lighten-3 pa-1 rounded">{{$_strings.order.MAKE_LUMPSUM}}</div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider v-for="divider in 3" :key="`${divider}+1`"></v-divider>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-card
            height="350"
            scrollable
            elevation="0"
          >
            <v-row class="mt-1">
              <v-col class="d-flex align-center" cols="12" sm="6">
                <span>{{$_strings.order.SELECT_3PL}}</span>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  placeholder="Cari Nama Rekanan 3PL"
                  v-model="search"
                  @keyup.native.enter="fetchListTransporter(true)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon @click="fetchListTransporter(true)">
                      mdi-magnify
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="listTransporter.length === 0 && !isLoadingGetList3PL">
              <p class="mt-15 headline">Tidak ada data</p>
            </v-row>
            <v-row justify="center" v-if="isLoadingGetList3PL">
              <v-progress-circular
                class="mt-15"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <section v-else>
              <v-row justify="center">
                <v-col
                  v-for="(transporter, index) in listTransporter"
                  :key="index"
                  cols="8"
                  sm="4">
                  <v-card
                    height="230"
                    @click="set3PL(transporter)"
                    :class="transporter === selected3PL ? 'border-card-active' : 'border-card-inactive'"
                  >
                    <div style="height:120px;">
                      <v-img
                        v-if="transporter.imageUrl"
                        :src="transporter.imageUrl"
                        width="120px"
                        contain
                        class="mx-auto"
                      >
                      </v-img>
                      <v-img
                        v-else
                        src="@/assets/images/userProfile.png"
                        width="120px"
                        class="mx-auto"
                      >
                      </v-img>
                    </div>
                    <p class="pb-0 red--text caption text-center">
                      <v-icon color="red" small>mdi-checkbox-marked-circle</v-icon> Rekomendasi
                    </p>
                    <v-card-subtitle class="pa-1 caption">
                      <p class="p_ellipsis">{{transporter.companyName}}</p>
                      <!-- <span>Harga</span>
                      <h4>{{formatPrice(transporter.price)}}</h4> -->
                      <div class="w-100 d-flex justify-end">
                        <v-icon x-small color="green">mdi-thumb-up</v-icon>
                        <span class="text--green ml-2">{{transporter.score}}%</span>
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="listTransporter.length > 0">
                <v-col class="d-flex" cols="12">
                  <span>Daftar 3PL {{pagination3PL.page + 1}} - {{pagination3PL.totalPage}} dari {{pagination3PL.totalData}}</span>
                  <v-spacer></v-spacer>
                  <section class="d-inline">
                    <v-icon
                      :color="pagination3PL.page === 0 ? 'grey' : 'primary'"
                      @click="handlePagination3PL('prev')"
                    >
                      mdi-arrow-left-drop-circle-outline
                    </v-icon>
                    <span>{{pagination3PL.page + 1}}</span>
                    <v-icon
                      :color="pagination3PL.page + 1 === pagination3PL.totalPage ? 'grey' : 'primary'"
                      @click="handlePagination3PL('next')"
                    >
                      mdi-arrow-right-drop-circle-outline
                    </v-icon>
                  </section>
                </v-col>
              </v-row>
              <div v-if="shipment.routeType !== 'LAND' && listTransporter.length > 0">
                <v-form ref="form_multimoda">
                  <v-row>
                    <v-col class="d-flex mb-0 pb-0" cols="6">
                      <span class="caption">{{$_strings.order.CRUISE_NUMBER}}</span>
                    </v-col>
                    <v-col class="d-flex mb-0 pb-0" cols="5">
                      <v-text-field
                        v-model="formMultimoda.flightCruiseNo"
                        outlined
                        dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <date-and-time
                    class="mt-3 caption"
                    label="Tanggal dan Jam Keberangkatan"
                    :tools="tools"
                    date="estimateDepartureDate"
                    time="estimateDepartureTime"
                    :requiredDate="true"
                    :requiredTime="true"
                  ></date-and-time>
                  <date-and-time
                    class="caption mt-1"
                    label="Tanggal dan Jam Kedatangan"
                    :tools="tools"
                    date="estimateArrivalDate"
                    time="estimateArrivalTime"
                    :requiredDate="true"
                    :requiredTime="true"
                  ></date-and-time>
                </v-form>
              </div>
            </section>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            @click="dialog = false"
          >
            {{$_strings.common.CANCEL}}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!selected3PL || isLoading"
            @click="saveTransporter"
          >
            {{$_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        const date = new Date();
        const { routeType } = this.shipment;
        // Multimoda
        this.tools.estimateDepartureDate = '';
        this.tools.estimateDepartureTime = '';
        this.tools.estimateArrivalDate = '';
        this.tools.estimateArrivalTime = '';
        this.form = {
          statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
          groupToShipmentCargo: '',
          nextFromShipmentCargo: [],
          continueFromShipmentCargo: '',
          shipmentsCargoId: '',
          isLumpSum: false,
          transporterCompanyId: '',
          mouRouteId: '',
        };
        this.checkGroupToShipment = false;
        this.checkContinueFromShipmentCargo = false;
        if (routeType !== 'LAND') {
          if (this.$refs.form_multimoda) {
            this.$refs.form_multimoda.reset();
          }
        }
        this.isAutoAssign = false;
        this.itemsCargo = [];
        this.shipment = {};
        this.search = '';
        this.selected3PL = '';
        this.listTransporter = [];
        this.pagination3PL = {
          page: 0,
          size: 3,
          totalPage: 0,
          totalData: 0,
        };
      }
      if (newVal) {
        source = CancelToken.source();
        this.form.shipmentsCargoId = this.shipment.shipmentsCargoId;
        this.fetchListTransporter();
        if (this.shipment.cargoGroupNumber) {
          this.checkGroupToShipment = true;
        }
        if (this.shipment.nextFromShipmentCargo && this.shipment.nextFromShipmentCargo.length) {
          this.checkContinueFromShipmentCargo = true;
        }
        if (this.shipment.estimateDeparture && !(this.shipment.status.toLowerCase() === 'pesanan dibuat')) {
          this.tools.estimateDepartureDate = dayjs(this.shipment.estimateDeparture).format('YYYY-MM-DD');
          this.tools.estimateDepartureTime = dayjs(this.shipment.estimateDeparture).format('HH:mm');
          this.formMultimoda.estimateDeparture = this.shipment.estimateDeparture;
        }
        if (this.shipment.estimateArrival && !(this.shipment.status.toLowerCase() === 'pesanan dibuat')) {
          this.tools.estimateArrivalDate = dayjs(this.shipment.estimateArrival).format('YYYY-MM-DD');
          this.tools.estimateArrivalTime = dayjs(this.shipment.estimateArrival).format('HH:mm');
          this.formMultimoda.estimateArrival = this.shipment.estimateArrival;
        }
        if (this.shipment.flightCruiseNo) {
          this.formMultimoda.flightCruiseNo = this.shipment.flightCruiseNo;
        }
      }
    },
    checkGroupToShipment(newVal) {
      if (!newVal) {
        this.form.groupToShipmentCargo = '';
        return;
      }
      if (this.shipment.cargoGroupNumber) {
        const lengthString = this.shipment.cargoGroupNumber.split('').length;
        const newCargoNumber = this.shipment.cargoGroupNumber.substring(1, lengthString);
        this.form.groupToShipmentCargo = newCargoNumber;
      }
    },
    checkContinueFromShipmentCargo(newVal) {
      if (!newVal) {
        this.form.nextFromShipmentCargo = [];
        return;
      }
      if (this.shipment.nextFromShipmentCargo && this.shipment.nextFromShipmentCargo.length) {
        this.form.nextFromShipmentCargo = this.shipment.nextFromShipmentCargo;
      }
    },
  },
  data() {
    const date = new Date();
    return {
      dialog: false,
      isAutoAssign: false,
      itemsCargo: [],
      shipment: {},
      checkGroupToShipment: false,
      checkContinueFromShipmentCargo: false,
      isLoading: false,
      isLoadingGetList3PL: false,
      search: '',
      selected3PL: '',
      listTransporter: [],
      pagination3PL: {
        page: 0,
        size: 3,
        totalPage: 0,
        totalData: 0,
      },
      // Multimoda
      tools: {
        estimateDepartureDate: '',
        estimateDepartureTime: '',
        estimateArrivalDate: '',
        estimateArrivalTime: '',
      },
      formMultimoda: {
        flightCruiseNo: '',
        estimateDeparture: '',
        estimateArrival: '',
      },
      form: {
        statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
        groupToShipmentCargo: '',
        nextFromShipmentCargo: [],
        continueFromShipmentCargo: '',
        isLumpSum: false,
        shipmentsCargoId: '',
        transporterCompanyId: '',
        mouRouteId: '',
      },
    };
  },
  methods: {
    dayjs,
    set3PL(item) {
      this.selected3PL = item;
      this.form.transporterCompanyId = item.companyId;
      this.form.mouRouteId = item.mouRouteId;
    },
    handlePagination3PL(val) {
      if (val === 'next' && this.pagination3PL.page < this.pagination3PL.totalPage - 1) {
        this.pagination3PL.page += 1;
        this.fetchListTransporter();
      }
      if (val === 'prev' && this.pagination3PL.page > 0) {
        this.pagination3PL.page -= 1;
        this.fetchListTransporter();
      }
    },
    fetchListTransporter(searchPage = false) {
      this.isLoadingGetList3PL = true;
      const {
        originLocationId, destinationLocationId, serviceType, transportTypeId, multiPick, multiDrop,
      } = this.shipment;

      const companyName = this.search;
      // SET PAGE TO 0 IF SEARCHING
      if (searchPage) {
        this.pagination3PL.page = 0;
      }

      const { page, size } = this.pagination3PL;
      const filters = {
        page,
        size,
        serviceType,
        transportTypesId: transportTypeId,
        originLocationId,
        destinationLocationId,
        companyName,
      };
      if (multiPick && multiPick.length) {
        filters.multiPickup = multiPick.map((pick) => pick.locationId).toString('');
      }
      if (multiDrop && multiDrop.length) {
        filters.multiDrop = multiDrop.map((pick) => pick.locationId).toString('');
      }
      this.$_services.order.getListTopTransporters(filters, source)
        .then((res) => {
          if (res.data.contents) {
            this.listTransporter = res.data.contents;
          } else {
            this.$dialog.notify.error('Data tidak ditemukan');
          }
          this.pagination3PL.totalPage = res.data.totalPage;
          this.pagination3PL.totalData = res.data.totalData;
        })
        .finally(() => {
          this.isLoadingGetList3PL = false;
        });
    },
    async saveTransporter() {
      const { routeType } = this.shipment;
      let valid = true;
      if (routeType !== 'LAND') {
        valid = this.$refs.form_multimoda.validate();
      }
      if (valid) {
        let _form = this.form;
        if (this.shipment.routeType !== 'LAND') {
          this.setTimeFormMultimoda();
          _form = {
            ...this.form,
            ...this.formMultimoda,
          };
        }
        if (this.isAutoAssign) return this.$emit('showDialogDriverAndTransport', this.form);
        try {
          this.isLoading = true;
          await this.$_services.order.saveSelecetedTransporter(_form, source);
          this.$emit('handleAfterAssign3PL', this.form);
          this.$dialog.notify.success('Transporter Berhasil Ditambahkan');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$dialog.notify.error('Mohon periksa kembali');
      }
    },
    formatPrice(price) {
      const formatNumber = Intl.NumberFormat('en-US');
      const isNumberOnly = /^\d+$/.test(price);
      if (isNumberOnly) {
        return formatNumber.format(price);
      }
    },
    setTimeFormMultimoda() {
      if (this.tools.estimateDepartureDate && this.tools.estimateDepartureTime) {
        const timeEstimationOfDepature = dayjs(`${this.tools.estimateDepartureDate} ${this.tools.estimateDepartureTime}`).format();
        this.formMultimoda.estimateDeparture = timeEstimationOfDepature;
      }

      if (this.tools.estimateArrivalDate && this.tools.estimateArrivalTime) {
        const timeEstimationArrived = dayjs(`${this.tools.estimateArrivalDate} ${this.tools.estimateArrivalTime}`).format();
        this.formMultimoda.estimateArrival = timeEstimationArrived;
      }
    },
  },
};
</script>
<style scoped>
  .v-card .scroll-card {
    overflow:scroll;
  }
  .v-card .border-card-active {
    border: 1px solid #1867c0;
  }
  .v-card .border-card-inactive {
    border: 1px solid #FFFF;
  }
  .p_ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
