<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1200"
  >
    <v-card :loading="loading" class="pa-2">
      <v-row class="ma-0 pa-0">
        <v-col class="d-flex ma-0 pa-0 justify-end">
          <v-toolbar-items>
            <v-btn
              icon
              color="black"
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
      <v-card-subtitle>
        <v-row>
          <v-col class="d-flex align-center pb-0" cols="12" md="auto">
            <h4>{{$_strings.order.MERGE_ORDER_LCL}}</h4>
          </v-col>
          <v-col cols="12" md="8" class="d-flex justify-space-between align-center pb-0">
            <div class="mt-2">
              <div class="box-title mr-5 mb-2 body-2">
                <span>{{$_strings.order.SEND_DATE}} : <span class="title-content">{{dateFormat(shipment.pickupDate)}}</span></span>
              </div>
              <div class="box-title body-2">
                <span>{{$_strings.order.ORDER_NO}} : <span class="title-content">{{shipment.groupTitle}}</span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <section class="mt-2">
          <v-container class="pa-0" fluid>
            <v-row class="mb-5">
              <v-col cols="12">
                <h4 class="d-block mb-5">{{$_strings.order.COMBINED_ORDERS}}</h4>
                <Combined
                  :isLoading="isLoadingListCombinedOrder"
                  :isError="isErrorGetListCombinedOrder"
                  :combinedItems="combinedItems"
                  :handleCheckbox="handleCheckbox"
                  @fetchData="fetchCombinedOrder"
                />
              </v-col>
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="auto">
                    <h4>{{$_strings.order.NOT_COMBINED_ORDERS}}</h4>
                  </v-col>
                  <v-col cols="12" sm="4" md="3">
                    <ProvinceFilter
                      class="my-4"
                      :dialog="dialog"
                      :provinceList="provinceList"
                      :isLoading="isLoadingProvinceList"
                      :isError="isErrorGetListCombinedOrder"
                      @fetchWantCombine="fetchWantCombine"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <NotCombined
                  :key="reCreatedTableNotCombinedOrder"
                  :isError="isErrorGetListNotCombinedOrder"
                  :isLoading="isLoadingListNotCombinedOrder"
                  :notCombinedItems="notCombinedItems"
                  :handleCheckbox="handleCheckbox"
                />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="dialog = false" class="ml-2" small color="red white--text">{{$_strings.common.CANCEL}}</v-btn>
            <v-btn @click="submit" :disabled="loading || !combinedItemsChecked.length" class="ml-2" small color="primary">{{$_strings.common.SAVE}}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';
import axios from 'axios';
import dayjs from 'dayjs';
import Combined from './Combined.vue';
import NotCombined from './NotCombined.vue';
import ProvinceFilter from './ProvinceFilter.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    Combined,
    ProvinceFilter,
    NotCombined,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isLoadingListCombinedOrder: false,
      isLoadingListNotCombinedOrder: false,
      isLoadingProvinceList: false,
      isErrorGetListCombinedOrder: false,
      isErrorGetListNotCombinedOrder: false,
      provinceList: [],
      combinedItems: [],
      wantCombined: [],
      shipment: {},
      reCreatedTableNotCombinedOrder: 0,
    };
  },
  computed: {
    notCombinedItems() {
      const datas = this.wantCombined.filter((x) => !this.shipmentId.includes(x.id));
      return datas.map((data) => ({
        ...data,
        checkbox: false,
      }));
    },
    combinedItemsChecked() {
      return this.combinedItems.filter((x) => x.checkbox);
    },
    shipmentId() {
      return this.combinedItemsChecked.map((item) => item.id);
    },
    pickupDate() {
      const { pickupDate } = this.shipment;
      let date = dayjs(pickupDate).valueOf();
      if (!this.combinedItemsChecked.length) return date;
      date = this.combinedItemsChecked[0].pickupDate;
      if (this.combinedItemsChecked.length === 1) return date;
      this.combinedItemsChecked.forEach((item) => {
        if (date > item.pickupDate) date = item.pickupDate;
      });
      return date;
    },
  },
  watch: {
    notCombinedItems() {
      this.reCreatedTableNotCombinedOrder += 1;
    },
    dialog() {
      if (this.dialog) {
        source = CancelToken.source();
        this.fetchCombinedOrder();
        if (!this.provinceList.length) this.fetchProvinceList();
      } else {
        this.combinedItems = [];
        this.wantCombined = [];
        source.cancel('CANCELED_BY_THE_USER');
      }
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    handleCheckbox(value, item, type) {
      if (type === 'combinedItems') {
        if (!value && !item.checkbox && !item.isCombined) this.combinedItems.splice(this.combinedItems.indexOf(item), 1);
      }
      if (type === 'notCombinedItems') {
        if (value) this.combinedItems.push({ ...item, checkbox: true });
      }
    },
    async fetchProvinceList() {
      try {
        this.isLoadingProvinceList = true;
        const result = await this.$_services.locations.provinces({
          page: 0,
          size: 50,
          sort: 'name,asc',
        });
        this.provinceList = result.data.contents;
      } finally {
        this.isLoadingProvinceList = false;
      }
    },
    async fetchCombinedOrder() {
      const shipmentGroupId = this.shipment.groupId;
      try {
        this.isErrorGetListCombinedOrder = false;
        this.isLoadingListCombinedOrder = true;
        const result = await this.$_services.order.getCombinedOrder(shipmentGroupId, source);
        this.combinedItems = result.data.map((order) => ({
          ...order,
          checkbox: true,
          isCombined: true,
        }));
      } catch {
        this.isErrorGetListCombinedOrder = true;
      } finally {
        this.isLoadingListCombinedOrder = false;
      }
    },
    async fetchWantCombine(originLocationProvinceIds, destinationLocationProvinceIds) {
      this.isLoadingListNotCombinedOrder = true;
      this.isErrorGetListNotCombinedOrder = false;
      const shipmentGroupId = this.shipment.groupId;
      const pickupDate = dayjs(this.shipment.pickupDate).format('YYYY-MM-DD');
      const form = {
        pickupDate,
        shipmentGroupId,
        originLocationProvinceIds,
        destinationLocationProvinceIds,
      };
      try {
        const result = await this.$_services.order.getWantCombine(form);
        this.wantCombined = result.data;
      } catch {
        this.isErrorGetListNotCombinedOrder = true;
      } finally {
        this.isLoadingListNotCombinedOrder = false;
      }
    },
    async submit() {
      const shipmentGroupId = this.shipment.groupId;
      try {
        this.loading = true;
        const pickupDate = dayjs(this.pickupDate).format();
        await this.$_services.order.submitCombineLcl(shipmentGroupId, pickupDate, this.shipmentId);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchOrders');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .box-title {
    background:#f8f8fb;
    width: max-content;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: rgb(161, 161, 161);
    .title-content {
      color: #4d4f5c;
    }
  }
</style>
