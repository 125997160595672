<template>
  <section class="pa-2">
    <v-row justify="end">
      <v-col cols="12" sm="6">
        <v-text-field
          placeholder="Cari Nama Sopir"
          outlined
          dense
          :value="pagination.name"
          class="caption"
          hide-details
          prepend-inner-icon="mdi-magnify"
          v-on:keyup.enter="fetchData($event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        class="ma-10"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row v-else-if="isError" class="pa-8" justify="center" >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="fetchData"
            x-large
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>
              mdi-reload
            </v-icon>
          </v-btn>
        </template>
        <span>Reload Halaman</span>
      </v-tooltip>
    </v-row>
    <v-row class="pa-8" justify="center" v-else-if="listDrivers.length === 0">
      <h2>{{$_strings.order.NO_DATA_DRIVER}}</h2>
    </v-row>
    <v-row v-else justify="center">
      <v-col v-for="(driver, index) in listDrivers" :key="index" class="pt-0 pb-0" sm="4">
        <v-card
          :class="form.companyDriverId === driver.id ? 'border-active' : 'border-inactive'"
          class="mx-auto mt-2"
          max-width="374"
          hover
          :ref="'vehicleSelected'+driver.id"
          @click="form.companyDriverId = driver.id"
        >
          <v-img
            v-if="!driver.imageUrl"
            height="80"
            width="374"
            contain
            src="@/assets/images/userProfile.png"
            aspect-ratio="1"
          ></v-img>
          <v-img
            v-else
            height="80"
            width="374"
            contain
            :src="driver.imageUrl"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title :class="!$vuetify.breakpoint.xs ? 'v-card-title-custom' : ''" class="d-flex flex-column align-start">
            <span class="caption">{{driver.platNo}}</span>
            <span class="caption font-weight-bold">{{driver.phoneNo}}</span>
            <span class="caption font-weight-medium">{{driver.name}}</span>
          </v-card-title>
          <v-card-text class="blue lighten-5">
            <v-btn aria-readonly v-if="driver.isExpired" class="red caption white--text" x-small>Expired</v-btn>
            <v-btn aria-readonly v-else-if="driver.isSuspended" class="red caption white--text" x-small>Suspend</v-btn>
            <v-btn v-else aria-readonly class="primary caption" x-small>Rekomendasi</v-btn>
            <div class="d-flex justify-end">
              <v-rating
                length="1"
                :value="1"
                color="amber"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>
              <div class="grey--text">
                {{toFixedScore(driver.score)}}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-pagination
      v-if="listDrivers.length > 0"
      style="position:absolute; right:0; bottom: 0;"
      v-model="pagination.page"
      :length="pagination.totalPage"
      circle
      @input="handlePagination"
    ></v-pagination>
  </section>
</template>
<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      listDrivers: [],
      pagination: {
        page: 1,
        size: 3,
        totalPage: 0,
        totalData: 0,
        name: '',
      },
    };
  },
  methods: {
    skipEmptyStringObject,
    handlePagination(val) {
      this.pagination.page = val;
      this.fetchData();
    },
    toFixedScore(score) {
      const includeDot = String(score).includes('.');
      if (includeDot) {
        return score.toFixed(2);
      }
      return score;
    },
    async fetchData(event = false) {
      if (event) {
        this.pagination.name = event.target.value;
        this.pagination.page = 1;
      }
      const { page, name } = this.pagination;
      const { shipmentCargoId, transporterCompanyId } = this.form;
      const filter = this.skipEmptyStringObject({
        page: page - 1,
        size: 3,
        cargoId: shipmentCargoId,
        isActive: true,
        name,
        transporterCompanyId,
      });
      this.form.companyDriverId = null;
      try {
        this.isLoading = true;
        this.isError = false;
        const result = await this.$_services.order.getListAssignDriver(filter);
        this.listDrivers = result.data.contents;
        this.pagination.totalPage = result.data.totalPage;
        this.pagination.totalData = result.data.totalData;
        this.$emit('setDriver');
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .border-active {
    border: 1px solid;
    border-color: blue;
  }
  .border-inactive {
    border: 1px solid;
    border-color: rgb(255, 255, 255);
  }
  .v-card-title-custom {
    span {
      display: inline-block;
      width: 120px;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
</style>
