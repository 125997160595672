<template>
  <div class="pa-2">
    <section v-if="isLoading">
      <v-row justify="center">
        <v-col
          v-for="s in 2"
          :key="s"
          cols="12"
          sm="4"
          md="6"
        >
          <v-skeleton-loader
            type="card-avatar"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <common-error
      v-else-if="isError"
      @fetchData="fetchTransporter"
    />
    <section v-else-if="transporterList.length === 0">
      <v-row align="center" justify="center" >
        <p class="mt-15  mb-10 headline">Tidak ada data</p>
      </v-row>
    </section>
    <section v-else>
      <v-row justify="center">
        <v-col
          v-for="(transporter, index) in transporterList"
          :key="index"
          cols="12"
          sm="4"
          md="6"
        >
          <v-card
            @click="$emit('set3PL', transporter)"
            :class="transporter === selected3PL ? 'border-card-active' : 'border-card-inactive'"
          >
            <div style="height:120px;">
              <v-img
                v-if="transporter.imageUrl"
                :src="transporter.imageUrl"
                width="100"
                contain
                class="mx-auto"
              >
              </v-img>
              <v-img
                v-else
                src="@/assets/images/userProfile.png"
                width="100px"
                class="mx-auto"
              >
              </v-img>
            </div>
            <p class="pb-0 red--text caption text-center">
              <v-icon color="red" small>mdi-checkbox-marked-circle</v-icon> Rekomendasi
            </p>
            <v-card-subtitle class="pa-1 caption">
              <p class="p_ellipsis">{{transporter.companyName}}</p>
              <div class="w-100 d-flex justify-end">
                <v-icon x-small color="green">mdi-thumb-up</v-icon>
                <span class="text--green ml-2">{{transporter.score}}%</span>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="transporterList.length > 0">
        <v-col class="d-flex" cols="12">
          <span class="text-caption">Daftar 3PL {{pagination.page + 1}} - {{pagination.totalPage}} dari {{pagination.totalData}}</span>
          <v-spacer></v-spacer>
          <section class="d-inline">
            <v-icon
              :color="pagination.page === 0 ? 'grey' : 'primary'"
              @click="handlePagination('prev')"
            >
              mdi-arrow-left-drop-circle-outline
            </v-icon>
            <span>{{pagination.page + 1}}</span>
            <v-icon
              :color="pagination.page + 1 === pagination.totalPage ? 'grey' : 'primary'"
              @click="handlePagination('next')"
            >
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </section>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { skipEmptyStringObject, getDateTimeTz } from '@/helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    selected3PL: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    lclType: {
      type: String,
      default: '',
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    isFetchData: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    source = CancelToken.source();
    this.fetchData();
  },
  watch: {
    isFetchData(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        this.search = '';
        this.transporterList = [];
        this.pagination = {
          page: 0,
          size: 3,
          totalPage: 0,
          totalData: 0,
        };
      }
    },
  },
  data() {
    return {
      search: '',
      isError: false,
      isLoading: false,
      transporterList: [],
      pagination: {
        page: 0,
        size: 3,
        totalPage: 0,
        totalData: 0,
      },
    };
  },
  methods: {
    getDateTimeTz,
    skipEmptyStringObject,
    fetchData() {
      if (this.isFetchData) this.fetchTransporter();
    },
    handlePagination(val) {
      if (val === 'next' && this.pagination.page < this.pagination.totalPage - 1) {
        this.pagination.page += 1;
        this.fetchTransporter();
      }
      if (val === 'prev' && this.pagination.page > 0) {
        this.pagination.page -= 1;
        this.fetchTransporter();
      }
    },
    async fetchTransporter(searchPage = false) {
      this.isError = false;
      this.isLoading = true;
      const {
        originLocationId,
        destinationLocationId,
        serviceType,
        transportTypeId,
      } = this.shipment;
      const companyName = this.search;
      const lclTypePrice = this.lclType;
      if (searchPage) {
        this.pagination.page = 0;
      }
      const { page, size } = this.pagination;
      const filter = skipEmptyStringObject({
        page,
        size,
        serviceType,
        transportTypesId: transportTypeId,
        originLocationId,
        destinationLocationId,
        lclTypePrice,
        companyName,
      });
      try {
        const result = await this.$_services.order.getListTopTransportersLcl(filter, source);
        this.transporterList = result.data.contents;
        this.pagination.totalPage = result.data.totalPage;
        this.pagination.totalData = result.data.totalData;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
  .v-card .border-card-active {
    border: 1px solid #1867c0;
  }
  .v-card .border-card-inactive {
    border: 1px solid #FFFF;
  }
  .p_ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
