var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.MULTIMODA_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.displayedHeaders,"calculate-widths":"","hide-default-footer":"","items":_vm.items,"items-per-page":100},scopedSlots:_vm._u([{key:"item.pickupDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.pickupDate)))]),_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.pickupDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0 pa-0",class:_vm.getColorStatus(item.status)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.showBtnBap(item))?_c('v-btn',{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.navigateToMultimodaBasp(item)}}},[_c('v-icon',[_vm._v(" mdi-circle-edit-outline ")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disableBtnAssignMultimoda(item.status.toLowerCase()),"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.handleEditMultimoda(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-circle-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.statusAllowedToAssign3pl.includes(item.status.toLowerCase()) ? _vm.$_strings.order.ASSIGN_3PL : _vm.$_strings.common.UPDATE)+" ")])])]}}],null,true)})],1)],1),_c('DialogAssign3PL',{ref:"dialogAssign3PL",on:{"handleAfterAssign3PL":_vm.handleAfterAssign3PL}}),_c('DialogAssign3PLLcl',{ref:"dialogAssign3PLLcl",on:{"handleAfterAssign3PL":_vm.handleAfterAssign3PL}}),_c('DialogUpdateMultimoda',{ref:"dialogUpdateMultimoda",on:{"handleAfterAssign3PL":_vm.handleAfterAssign3PL}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }