<template>
  <v-dialog
    :key="dialog"
    v-model="dialog"
    persistent
    scrollable
    width="500"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" class="pb-1">
              <h3>{{$_strings.order.CONFIRMATION_DATE}}</h3>
            </v-col>
            <v-col class="pt-0">
              <v-row>
                <v-col class="pb-0" cols="auto">
                  <v-menu
                    ref="menuCofirmDate"
                    v-model="menuCofirmDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    class="caption"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        class="caption"
                        placeholder="Tanggal"
                        prepend-inner-icon="mdi-calendar"
                        :value="form.confirmationDate && dateFormat(form.confirmationDate)"
                        :rules="rules.date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.confirmationDate"
                      @input="menuCofirmDate = false"
                      :min="minDate()"
                      :max="maxDate()"
                      @change="handleDate"
                      no-title
                      scrollable
                      locale="id"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="auto">
                  <v-text-field
                    outlined
                    dense
                    ref="time"
                    placeholder="Jam"
                    v-mask="{'alias': 'time', rightAlign: false}"
                    @input="setPickupDate"
                    v-model="time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    class="caption"
                    :rules="[($event) => rules.time($event)]"
                    :value="time && timeFormat(time)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDateTimeTz, dateFormat, timeFormat } from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import axios from 'axios';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      menuCofirmDate: false,
      rules: {
        date: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Tanggal'),
        ],
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          return true;
        },
      },
      time: null,
      shipment: {},
      form: {
        confirmationDate: null,
        datetimeTz: this.getDateTimeTz(),
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        source = CancelToken.source();
      }
      if (!newVal) {
        this.time = null;
        this.form = {
          datetimeTz: this.getDateTimeTz(),
          confirmationDate: null,
        };
        source.cancel('CANCELED_BY_THE_USER');
      }
    },
  },
  methods: {
    getDateTimeTz,
    dateFormat,
    timeFormat,
    minDate() {
      return dayjs(this.shipment.pickupDate).format();
    },
    maxDate() {
      // return dayjs(this.shipment.pickupDate).add(1, 'months').format();
      return null;
    },
    setPickupDate(time) {
      const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (this.form.confirmationDate && timePattern.test(time)) {
        const date = dayjs(this.form.confirmationDate).format('YYYY-MM-DD');
        const confirmationDate = dayjs(`${date} ${time}`).format();
        this.form.confirmationDate = confirmationDate;
      }
    },
    handleDate() {
      this.setPickupDate(this.time);
      this.$refs.time.focus();
    },
    async submit() {
      try {
        const isValid = this.$refs.form.validate();
        if (!isValid) return;
        this.isLoading = true;
        const type = 'driver';
        const shipmentsCargoid = this.shipment.id;
        const form = {
          datetimeTz: this.form.datetimeTz,
          confirmationDate: this.form.confirmationDate,
        };
        await this.$_services.order.confirmation(shipmentsCargoid, form, type);
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchOrders');
        this.$emit('refreshDataOnPageUnconfirmed');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
