<template>
  <section>
    <v-row>
      <v-col>
        <h4 class="pl-2 d-inline">{{$_strings.order.ROUTE_INFO}}</h4>
        <v-btn
          v-if="menuFunctions.EDIT_INFORMASI_RUTE"
          class="ml-2"
          color="primary"
          small
          @click="showDialogRoute"
        >
          {{$_strings.order.CHANGE}}
        </v-btn>
      </v-col>
    </v-row>
    <DialogRoute
      ref="dialogRoute"
      @refecthData="refecthData"
    />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import DialogRoute from '../Dialog/ShipmentRoute';

export default {
  props: {
    status: {
      type: String,
      default: () => '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogRoute,
  },
  methods: {
    showDialogRoute() {
      if (this.item.subOrder.routes.length) {
        this.fetchRouteData();
        return;
      }
      this.setDataDialogRoute();
    },
    setDataDialogRoute(data = []) {
      const { pickupDate } = this.item;
      const isBackdate = dayjs(pickupDate).format() < dayjs().format();
      if (data.length) this.$refs.dialogRoute.routeData = data;
      this.$refs.dialogRoute.pickupDate = pickupDate;
      this.$refs.dialogRoute.isBackdate = isBackdate;
      this.$refs.dialogRoute.form.shipmentsGroupId = this.item.groupId;
      this.$refs.dialogRoute.item = this.item;
      this.$refs.dialogRoute.dialog = true;
    },
    async fetchRouteData() {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.order.getRoute(this.item.groupId);
        this.setDataDialogRoute(result.data);
      } finally {
        this.$root.$loading.hide();
      }
    },
    refecthData() {
      this.$delete(this.item, 'subOrder');
      this.$emit('fetchSubOrder', this.item);
    },
  },
};
</script>
