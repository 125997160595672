var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.TRIP_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.displayedHeadersMultipickInfo,"items":_vm.item.subOrder.shipmentResponse,"items-per-page":100,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.originalLocation",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.originalLocation.name)+" ")])]}},{key:"item.multiPick",fn:function(ref){
var item = ref.item;
return _vm._l((item.multiPick),function(multiPick,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(multiPick.name)+" ")])})}}],null,true)})],1),_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.displayedHeadersMultidropInfo,"items":_vm.item.subOrder.shipmentResponse,"items-per-page":100,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.destinationLocation",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.destinationLocation.name)+" ")])]}},{key:"item.multiDrop",fn:function(ref){
var item = ref.item;
return _vm._l((item.multiDrop),function(multiDrop,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(multiDrop.name)+" ")])})}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }