<template>
  <section>
    <v-row>
      <v-col><h4 class="pl-2">{{$_strings.order.TRIP_INFO}}</h4></v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeadersMultipickInfo"
          :items="item.subOrder.shipmentResponse"
          :items-per-page="100"
          calculate-widths
          hide-default-footer
          class="grey lighten-4"
        >
          <template v-slot:[`item.originalLocation`]="{item}">
            <p>
              {{item.originalLocation.name}}
            </p>
          </template>
          <template v-slot:[`item.multiPick`]="{item}">
            <p v-for="(multiPick, index) in item.multiPick" :key="index">
              {{ index + 1 }} - {{multiPick.name}}
            </p>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeadersMultidropInfo"
          :items="item.subOrder.shipmentResponse"
          :items-per-page="100"
          calculate-widths
          hide-default-footer
          class="grey lighten-4"
        >
          <template v-slot:[`item.destinationLocation`]="{item}">
            <p>
              {{item.destinationLocation.name}}
            </p>
          </template>
          <template v-slot:[`item.multiDrop`]="{item}">
            <p v-for="(multiDrop, index) in item.multiDrop" :key="index">
              {{ index + 1 }} - {{multiDrop.name}}
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import RenderFunction from './function';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    displayedHeadersMultipickInfo() {
      const renderFunction = new RenderFunction(this);
      return renderFunction.tableHeader('multipick');
    },
    displayedHeadersMultidropInfo() {
      const renderFunction = new RenderFunction(this);
      return renderFunction.tableHeader('multidrop');
    },
  },
};
</script>
