var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.QUANTITY_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.displayedHeaders,"calculate-widths":"","hide-default-footer":"","items":_vm.items,"items-per-page":100},scopedSlots:_vm._u([{key:"item.calculation",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","icon":"","disabled":_vm.disableBtnCalculate(item)},on:{"click":function($event){return _vm.showDialogChangeQty(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-autorenew ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.CALCULATE_QTY))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.showBtnCalculationProcess(item))?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.calculationProcess}},[_vm._v(" "+_vm._s(_vm.$_strings.order.CALCULATION_PROCESS)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('DialogChangeQuantity',{ref:"dialogChangeQuantity",attrs:{"isDelivery":_vm.$route.params.status === 'process'},on:{"handleAfterChangeQty":_vm.handleAfterChangeQty}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }