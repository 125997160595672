<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    scrollable
  >
    <v-card>
      <v-card-title class="pb-0 caption">
        <h3>{{$_strings.order.ASSIGN_3PL}}</h3>
        <v-row>
          <v-col cols="12" class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row>
          <v-col cols="12" md="5">
            <sidebar-form
              ref="sidebar_form"
              :form="form"
              :shipment="shipment"
              :toolsForm="toolsForm"
              :itemsCargo="itemsCargo"
              :formChecklist="formChecklist"
            />
          </v-col>
          <v-col cols="12" md="7">
            <list-transporter
              :form="form"
              :selected3PL="selected3PL"
              :shipment="shipment"
              :isFetchData="dialog"
              @set3PL="set3PL"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="submitTransporter"
          :disabled="!selected3PL"
          :loading="isLoading"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDateTimeTz } from '@/helper/commonHelpers';
import axios from 'axios';
import dayjs from 'dayjs';
import SidebarForm from './SidebarForm.vue';
import ListTransporter from './ListTransporter.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    SidebarForm,
    ListTransporter,
  },
  watch: {
    dialog(newVal) {
      // RESET VALUE
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        this.selected3PL = null;
        this.formChecklist = {
          checkGroupToShipment: false,
          checkContinueFromShipmentCargo: false,
        };
        this.toolsForm = {
          estimateDepartureDate: '',
          estimateDepartureTime: '',
          estimateArrivalDate: '',
          estimateArrivalTime: '',
        };
        this.isAutoAssign = false;
        this.form = {
          statusDatetimeTz: this.getDateTimeTz(),
          groupToShipmentCargo: '',
          nextFromShipmentCargo: [],
          continueFromShipmentCargo: '',
          isLumpSum: false,
          shipmentsCargoId: '',
          transporterCompanyId: '',
          mouRouteId: '',
        };
        return;
      }
      source = CancelToken.source();
      this.form.shipmentsCargoId = this.shipment.shipmentsCargoId;
      if (this.shipment.cargoGroupNumber) {
        this.formChecklist.checkGroupToShipment = true;
      }
      if (this.shipment.nextFromShipmentCargo && this.shipment.nextFromShipmentCargo.length) {
        this.formChecklist.checkContinueFromShipmentCargo = true;
      }
      if (this.shipment.estimateDeparture && !(this.shipment.status.toLowerCase() === 'pesanan dibuat')) {
        this.toolsForm.estimateDepartureDate = dayjs(this.shipment.estimateDeparture).format('YYYY-MM-DD');
        this.toolsForm.estimateDepartureTime = dayjs(this.shipment.estimateDeparture).format('HH:mm');
        this.formMultimoda.estimateDeparture = this.shipment.estimateDeparture;
      }
      if (this.shipment.estimateArrival && !(this.shipment.status.toLowerCase() === 'pesanan dibuat')) {
        this.toolsForm.estimateArrivalDate = dayjs(this.shipment.estimateArrival).format('YYYY-MM-DD');
        this.toolsForm.estimateArrivalTime = dayjs(this.shipment.estimateArrival).format('HH:mm');
        this.formMultimoda.estimateArrival = this.shipment.estimateArrival;
      }
      if (this.shipment.flightCruiseNo) {
        this.formMultimoda.flightCruiseNo = this.shipment.flightCruiseNo;
      }
    },
  },
  data() {
    return {
      dialog: false,
      isAutoAssign: false,
      isLoading: false,
      shipment: {},
      selected3PL: null,
      itemsCargo: [],
      formChecklist: {
        checkGroupToShipment: false,
        checkContinueFromShipmentCargo: false,
      },
      toolsForm: {
        estimateDepartureDate: '',
        estimateDepartureTime: '',
        estimateArrivalDate: '',
        estimateArrivalTime: '',
      },
      formMultimoda: {
        flightCruiseNo: '',
        estimateDeparture: '',
        estimateArrival: '',
      },
      form: {
        statusDatetimeTz: this.getDateTimeTz(),
        groupToShipmentCargo: '',
        nextFromShipmentCargo: [],
        continueFromShipmentCargo: '',
        isLumpSum: false,
        shipmentsCargoId: '',
        transporterCompanyId: '',
        mouRouteId: '',
      },
    };
  },
  methods: {
    dayjs,
    getDateTimeTz,
    set3PL(item) {
      this.selected3PL = item;
      this.form.transporterCompanyId = item.companyId;
      this.form.mouRouteId = item.mouRouteId;
    },
    setTimeFormMultimoda() {
      if (this.toolsForm.estimateDepartureDate && this.toolsForm.estimateDepartureTime) {
        const timeEstimationOfDepature = dayjs(`${this.toolsForm.estimateDepartureDate} ${this.toolsForm.estimateDepartureTime}`).format();
        this.formMultimoda.estimateDeparture = timeEstimationOfDepature;
      }

      if (this.toolsForm.estimateArrivalDate && this.toolsForm.estimateArrivalTime) {
        const timeEstimationArrived = dayjs(`${this.toolsForm.estimateArrivalDate} ${this.toolsForm.estimateArrivalTime}`).format();
        this.formMultimoda.estimateArrival = timeEstimationArrived;
      }
    },
    async submitTransporter() {
      const { routeType } = this.shipment;
      let valid = true;
      if (routeType !== 'LAND') {
        valid = this.$refs.sidebar_form.$refs.form_multimoda.validate();
      }
      if (valid) {
        let _form = this.form;
        if (this.shipment.routeType !== 'LAND') {
          this.setTimeFormMultimoda();
          _form = {
            ...this.form,
            ...this.formMultimoda,
          };
        }
        if (this.isAutoAssign) return this.$emit('showDialogDriverAndTransport', this.form);
        try {
          this.isLoading = true;
          await this.$_services.order.saveSelecetedTransporter(_form, source);
          this.$dialog.notify.success('Transporter Berhasil Ditambahkan');
          this.dialog = false;
          this.$emit('handleAfterAssign3PL');
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$dialog.notify.error('Mohon periksa form yang wajib diisi');
      }
    },
  },
};
</script>
