<template>
  <section>
    <v-container ref="container" class="pa-0 mb-5" fluid>
      <FilterOrder
        v-bind="$props"
        :orderBy="orderByTitle"
        :filters="filters"
        @fetchOrders="fetchOrders"
        @fetching="$emit('fetching', status)"
      />
      <v-sheet>
        <v-data-table
          item-key="index"
          show-expand
          class="body pt-4"
          :headers="displayedHeader"
          :items="items"
          :expanded.sync="expanded"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page
          }"
          :loading="isLoading"
          :loading-text="$_strings.order.LOADING_TEXT"
          @click:row="viewDetailOrder"
          @item-expanded="({value, item}) => value && fetchSubOrder(item)"
        >
          <template v-slot:[`item.serviceType`]={item}>
            <span>
              {{item.serviceType === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : item.serviceType}}
            </span>
          </template>
          <template v-slot:[`item.pickupDate`]='{item}'>
            <p class="ma-0"> {{dateFormat(item.pickupDate)}}</p>
            <p>{{timeFormat(item.pickupDate)}}</p>
          </template>
          <template v-slot:[`item.transportModel`]={item}>
            <span>{{transportModel(item.transportModel)}}</span>
          </template>
          <template v-slot:[`item.latestStatus`]='{item}'>
            <span>{{removeUnderscoreString(item.latestStatus)}}</span>
          </template>
          <template v-slot:[`item.action`]={item}>
            <btn-actions
              :item="item"
              :status="status"
              @fetchOrders="fetchOrders"
              @refreshDataOnPage="(_status) => $emit('fetching', _status)"
            />
          </template>
          <!-- expanded -->
          <template v-slot:expanded-item="{ headers, item }">
            <expanded-item-list
              :headers="headers"
              :item="item"
              :status="status"
              :itemTemporary="itemTemporary"
              @fetchOrders="fetchOrders"
              @fetchSubOrder="fetchSubOrder"
              @isHasWaitingOrder="isHasWaitingOrder"
              @fetchNewLegend="fetchNewLegend"
              @viewDetailOrder="viewDetailOrder"
              @navigateToPageUpdateOrders="navigateToPageUpdateOrders"
            />
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.order.PAGE_NAME}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>
  </section>
</template>

<script>
import {
  dateFormat,
  timeFormat,
  skipEmptyStringObject,
  handleSortBy,
  transportModel,
  handlerPagination,
} from '@/helper/commonHelpers';
import axios from 'axios';
import RenderFunction from './function';
import ExpandedItemList from './ExpandedItemList.vue';
import BtnActions from './BtnActions.vue';
import FilterOrder from '../FilterOrder.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'order-list-waiting',
  props: {
    status: {
      type: String,
      default: '',
    },
    itemsFilter: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    FilterOrder,
    ExpandedItemList,
    BtnActions,
  },
  created() {
    source = CancelToken.source();
  },
  // beforeDestroy() {
  //   const { status } = this.$route.params;
  //   if (this.status !== status) source.cancel('CANCELED_BY_THE_USER');
  // },
  data() {
    const renderFunction = new RenderFunction(this);
    return renderFunction.data;
  },
  computed: {
    orderByTitle() {
      switch (this.status) {
        case 'unconfirmed': return 'Pesanan Menunggu';
        case 'process': return 'Dalam Proses';
        case 'confirmation_transporter': return 'Pesanan Menunggu Konfirmasi 3PL';
        case 'confirmation_driver': return 'Pesanan Menunggu Konfirmasi Driver';
        default: return 'Selesai';
      }
    },
    displayedHeader() {
      const canUpdate = true;
      const orderStatusConfirmation = ['confirmation_transporter', 'confirmation_driver'];
      const renderFunction = new RenderFunction(this);
      let renderHeader = renderFunction.tableHeader();
      if (orderStatusConfirmation.includes(this.status)) renderHeader = renderFunction.tableHeader('confirmation');
      const showColumnAction = canUpdate && ['unconfirmed', 'confirmation_transporter', 'confirmation_driver'].includes(this.status);
      return renderHeader.filter((header) => {
        if (header.value === 'action' && !showColumnAction) return;
        return header;
      });
    },
    waitingOrder() {
      return this.$store.getters['user/waitingOrder'];
    },
  },
  watch: {
    $route: function setHistoryPath(newVal, oldVal) {
      if (oldVal.params.status === this.status) {
        this.historyPath = oldVal.fullPath;
      }
    },
    '$route.path': function setQueryString(path) {
      const { status } = this.$route.params;
      const { query } = this.$route;
      if (this.status === status && !Object.keys(query).length) {
        if (this.historyPath && this.historyPath !== path) this.$router.replace(this.historyPath);
      }
    },
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchOrders();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    skipEmptyStringObject,
    handleSortBy,
    transportModel,
    removeUnderscoreString(string) {
      if (!string) return '-';
      const str = string.replace(/_/g, ' ')
        .toLowerCase()
        .replace(/^\w|\s\w/g, (s) => s.toUpperCase());
      return str;
    },
    showLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    setFilterListConfirmation(filter) {
      return this.skipEmptyStringObject({
        cargoTitle: filter.shipmentNumber,
        pickupDateFrom: filter.shipmentDateStart,
        pickupDateTo: filter.shipmentDateEnd,
        shipperAbbreviation: filter.shipperName,
        page: filter.page,
        size: filter.size,
        sort: filter.sort,
        transporterId: filter.transporterId,
      });
    },
    orderService() {
      const {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
      } = this.pagination;
      const defaultValue = this.status === 'unconfirmed' ? 'canCombine,ASC' : '';
      let filters = this.skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue, sortBy, sortDesc }),
        ...this.filters,
      });
      if (this.status === 'confirmation_transporter' || this.status === 'confirmation_driver') {
        filters = this.setFilterListConfirmation(filters);
        const confirmationType = this.status.match(/([^_])([a-z])*$/)[0];
        return this.$_services.order.getListShipmentsConfirmation(filters, confirmationType, source);
      }
      return this.$_services.order.getListShipments(filters, this.status.toUpperCase(), source);
    },
    async fetchSubOrder(order) {
      if (order.subOrder) return;
      try {
        this.$set(order, 'subOrderLoading', true);
        const result = await this.$_services.order.getListShipmentDetail(order.groupId);
        this.$set(order, 'subOrder', result.data);
      } finally {
        this.$set(order, 'subOrderLoading', false);
      }
    },
    async isHasWaitingOrder() {
      const res = await this.$_services.order.isHasWaitingOrder();
      this.$store.dispatch('user/setWaitingOrder', res.data);
    },
    async fetchOrders() {
      try {
        this.showLoading(true);
        const result = await this.orderService();
        this.expanded = [];
        this.items = result.data.contents.map((i, index) => ({
          ...i,
          type: 'Order',
          subOrderLoading: false,
          index,
        }));
        this.totalItems = result.data.totalData;
        this.componentCreated = true;
      } finally {
        this.showLoading(false);
      }
    },
    async fetchNewLegend(item) {
      try {
        this.$set(item, 'isLoadingNewLegend', true);
        const result = await this.orderService();
        this.items[item.index].warning = result.data.contents[item.index].warning;
      } finally {
        this.$delete(item, 'isLoadingNewLegend');
      }
    },
    navigateToPageUpdateOrders(item, shipment) {
      if (!this.$route.path.match(/update-orders/)) return;
      const { shipmentDateStart, shipmentDateEnd } = this.filters;
      const pathName = 'update-order-detail';
      const query = {
        status: this.status,
        groupTitle: item.groupTitle,
        serviceType: item.serviceType,
        shipmentDateStart,
        shipmentDateEnd,
      };
      this.$router.push({
        name: pathName,
        params: {
          groupId: item.groupId,
          shipmentsId: shipment.id,
          method: 'update',
          data: item,
        },
        query,
      });
    },
    viewDetailOrder(item) {
      const { shipmentDateStart, shipmentDateEnd } = this.filters;
      // THIS COMPONENT IS USED IN 2 PATHS
      let pathName = 'order-detail';
      const query = {
        status: this.status,
        groupTitle: item.groupTitle,
        serviceType: item.serviceType,
        shipmentDateStart,
        shipmentDateEnd,
      };
      if (this.$route.path.match(/update-orders/)) {
        pathName = 'update-detail';
        query.cargoTitle = item.cargoTitle;
      }
      this.$router.push({
        name: pathName,
        params: {
          groupId: item.groupId,
          data: item,
        },
        query,
      });
    },
  },
};
</script>
