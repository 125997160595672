<template>
  <v-container fluid class="mb-5">
    <v-row>
      <v-col>
        <v-card :class="isMobile ? 'pa-0 ma-0': 'pl-2 pr-2' " elevation="0" tile>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab"
              @click="changePath(tab)"
              class="font-weight-bold text-capitalize"
            >
              {{getTabTitle(tab)}}
            </v-tab>
          </v-tabs>
          <v-divider style="margin-top:-2px" />
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="tab in tabs" :key="tab">
        <MainList
          :status="tab"
          :key="key[tab]"
          :itemsFilter="itemsFilter"
          :pageFilters="pageFilters"
          @fetching="fetching"
        />
      </v-tab-item>
    </v-tabs-items>
    <FooterOrder v-if="showFooter" :status="status"/>
  </v-container>
</template>

<script>

import FooterOrder from './FooterOrder.vue';
import MainList from './List/MainList.vue';

export default {
  name: 'index',
  components: {
    FooterOrder,
    MainList,
  },
  created() {
    const { status } = this.$route.params;
    this.handleTabsUpdateOrders();
    this.setActiveTab(status);
  },
  data() {
    return {
      path: '',
      activeTab: 0,
      itemsFilter: {
        itemsCustomers: [],
        itemsTransporter: [],
        itemsLocation: [],
      },
      pageFilters: {
        customer: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        transporter: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        location: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
      },
      key: {
        unconfirmed: 0,
        process: 0,
        finished: 0,
        confirmation_transporter: 0,
        confirmation_driver: 0,
      },
      tabs: ['unconfirmed', 'confirmation_transporter', 'confirmation_driver', 'process', 'finished'],
      reFetchListOrderComplete: 0,
    };
  },
  computed: {
    status() {
      const { status } = this.$route.params;
      return status;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    currentPath() {
      return this.$route.path;
    },
    showFooter() {
      const showFooterInPage = ['unconfirmed', 'process'];
      return showFooterInPage.includes(this.status);
    },
    basePathOrder() {
      const basePathOrder = this.$route.path.split('/')[2];
      return basePathOrder;
    },
  },
  watch: {
    currentPath() {
      const { status } = this.$route.params;
      this.setActiveTab(status);
    },
  },
  methods: {
    handleTabsUpdateOrders() {
      if (this.basePathOrder !== 'update-orders') {
        this.tabs.splice(1, 2);
      }
    },
    fetching(tab) {
      this.key[tab] += 1;
    },
    getTabTitle(tab) {
      const title = {
        unconfirmed: this.$_strings.order.ORDER_TAB.WAITING,
        process: this.$_strings.order.ORDER_TAB.PROCESS,
        finished: this.$_strings.order.ORDER_TAB.COMPLETED,
        confirmation_transporter: this.$_strings.order.ORDER_TAB.TRANSPORTER_CONFIRMATION,
        confirmation_driver: this.$_strings.order.ORDER_TAB.DRIVER_CONFIRMATION,
      };
      return title[tab];
    },
    changePath(path) {
      if (this.path.localeCompare(path) !== 0) {
        this.path = path;
        const replacePath = this.$route.path.replace(/\/\w*$/, `/${path}`);
        this.$router.replace(replacePath);
      }
    },
    setActiveTab(status) {
      this.path = status;
      this.activeTab = this.tabs.indexOf(status);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-data-table-header {
  tr {
    .amber.accent-3 {
      background-color: #f1c231 !important;
    }
  }
}
</style>
