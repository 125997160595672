var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"container"},[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.SHIPPER_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"calculate-widths":"","hide-default-footer":"","headers":_vm.displayedHeadersShipperInfo,"items":_vm.item.subOrder.shipmentResponse || [],"items-per-page":100},scopedSlots:_vm._u([{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.orderDate)))]),_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.orderDate)))])]}},{key:"item.senderName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ma-0"},[_vm._v(_vm._s(item.originalLocation.picName || '-'))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.showBtnCancelTheOrder)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","small":"","icon":"","disabled":_vm.disableBtnCancelOrder(item)},on:{"click":function($event){return _vm.toggleDialog('dialogCancelOrder', item)},"mouseover":function($event){return _vm.hoverBtnCancelOrder('cancel', true)},"mouseleave":function($event){return _vm.hoverBtnCancelOrder('cancel', false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.CANCEL_ORDER))])]):_vm._e(),(_vm.showBtnCompleteTheOrder)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary","disabled":_vm.disableBtnCompleteTheOrder(item)},on:{"click":function($event){return _vm.toggleDialog('dialogCompleteTheOrder', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.COMPLETE_THE_ORDER))])]):_vm._e(),(_vm.showBtnUpdateOrder)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"blue lighten-2","disabled":_vm.disableBtnUpdateStatus(item)},on:{"click":function($event){return _vm.$emit('navigateToPageUpdateOrders', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.UPDATE_STATUS))])]):_vm._e(),(_vm.showBtnResendToOracle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green","small":"","icon":"","loading":item.isLoadingResendToOracale,"disabled":_vm.disableBtnResendToOracle(item.orderStatus)},on:{"click":function($event){return _vm.handleResendToOracle(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-rotate-3d-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.RESEND_TO_ORACLE))])]):_vm._e()]}}],null,true)})],1)],1),_c('DialogCancelOrder',{ref:"dialogCancelOrder",on:{"cancelOrder":_vm.cancelOrder}}),_c('DialogCompleteTheOrder',{ref:"dialogCompleteTheOrder",on:{"completeOrder":_vm.completeOrder}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }