<template>
  <section class="pa-2">
    <v-row justify="end">
      <v-col cols="12" sm="6">
        <v-text-field
          placeholder="Cari Nomor Kendaraan"
          outlined
          dense
          class="caption"
          hide-details
          prepend-inner-icon="mdi-magnify"
          v-on:keyup.enter="fetchData"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row  v-if="isLoading" justify="center">
      <v-progress-circular
        :size="50"
        class="ma-10"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row v-else-if="isError" class="pa-8" justify="center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="fetchData"
            x-large
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>
              mdi-reload
            </v-icon>
          </v-btn>
        </template>
        <span>Reload Halaman</span>
      </v-tooltip>
    </v-row>
    <v-row class="pa-8" justify="center" v-else-if="listVehicle.length === 0">
      <h2>{{$_strings.common.NO_DATA}}</h2>
    </v-row>
    <v-row v-else justify="center">
      <v-col v-for="(vehicle, index) in listVehicle" :key="index" class="pt-0 pb-0" sm="4">
        <v-card
          :class="form.transportId === vehicle.id ? 'border-active' : 'border-inactive'"
          class="mx-auto mt-2"
          max-width="374"
          hover
          :ref="'vehicleSelected'+vehicle.id"
          @click="form.transportId = vehicle.id"
        >
          <div
            v-if="!vehicle.imageUrl"
            style="height:120px;"
            class="d-flex justify-center align-center"
          >
            <v-icon
              x-large
            >
              mdi-truck-outline
            </v-icon>
          </div>
          <v-img
            v-else
            height="120"
            width="374"
            contain
            :src="vehicle.imageUrl"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title class="d-flex flex-column align-start">
            <span class="caption">{{vehicle.licensePlate}}</span>
            <v-btn
              v-if="isExpired(vehicle.kirExpiredDate)"
              aria-readonl
              class="red caption white--text mt-2"
              x-small
            >
              KIR Expired
            </v-btn>
            <v-btn
              v-if="isExpired(vehicle.stnkExpiredDate)"
              aria-readonly
              class="red caption white--text mt-2"
              x-small
            >
              STNK Expired
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-pagination
      v-if="listVehicle.length > 0"
      style="position:absolute; right:0; bottom: 0"
      v-model="pagination.page"
      :length="pagination.totalPage"
      circle
      @input="handlePagination"
    ></v-pagination>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import { skipEmptyStringObject } from '@/helper/commonHelpers';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      isError: false,
      isLoading: false,
      pagination: {
        page: 1,
        size: 3,
        totalPage: 0,
        totalData: 0,
        name: '',
      },
      listVehicle: [],
    };
  },
  methods: {
    skipEmptyStringObject,
    isExpired(ExpiredDate) {
      const dateNow = dayjs().format();
      const expiredDate = dayjs(ExpiredDate);
      const diffExpired = expiredDate.diff(dateNow, 'day');
      if (diffExpired <= 0) {
        return true;
      }
      return false;
    },
    handlePagination(val) {
      this.pagination.page = val;
      this.fetchData();
    },
    async fetchData(event = false) {
      if (event) {
        this.pagination.name = event.target.value;
        this.pagination.page = 1;
      }
      const { page, name } = this.pagination;
      const { shipmentCargoId, transporterCompanyId } = this.form;
      const filters = this.skipEmptyStringObject({
        page: page - 1,
        size: 3,
        cargoId: shipmentCargoId,
        licensePlate: name,
        transporterCompanyId,
      });
      this.form.transportId = null;
      try {
        this.isLoading = true;
        this.isError = false;
        const result = await this.$_services.order.getListAssignTransporter(filters);
        this.listVehicle = result.data.contents;
        this.pagination.totalPage = result.data.totalPage;
        this.pagination.totalData = result.data.totalData;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .border-active {
    border: 1px solid;
    border-color: blue;
  }
  .border-inactive {
    border: 1px solid;
    border-color: rgb(255, 255, 255);
  }
  .v-card-title-custom {
    span {
      display: inline-block;
      width: 120px;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
</style>
