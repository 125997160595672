<template>
  <div>
    <section>
      <v-row>
        <v-col class="pt-0 ma-0 justify-center align-center" cols="12" md="6">
          <v-checkbox hide-details v-model="formChecklist.checkContinueFromShipmentCargo">
            <template v-slot:label>
              <div class="caption grey lighten-3 pa-1 rounded">
                {{$_strings.order.IS_AN_ADVANCED_ROUTE}}
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="form.nextFromShipmentCargo"
            item-text="cargoNumber"
            item-value="cargoNumber"
            dense
            outlined
            hide-details
            flat
            multiple
            :disabled="!formChecklist.checkContinueFromShipmentCargo"
            :items="itemsCargo.filter((item) => item.cargoNumber !== shipment.cargoNumber)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 ma-0" cols="12" md="6">
          <v-checkbox
            hide-details
            v-model="formChecklist.checkGroupToShipment"
          >
            <template v-slot:label>
              <div class="caption grey lighten-3 pa-1 rounded">{{$_strings.order.MAKE_CARGO_GROUP}}</div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.groupToShipmentCargo"
            :disabled="!formChecklist.checkGroupToShipment"
            dense
            outlined
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
    </section>
    <section v-if="shipment.routeType !== 'LAND'">
      <v-form ref="form_multimoda">
        <v-row
          align="center"
        >
          <v-col cols="12" md="6">
            <span class="caption">{{$_strings.order.CRUISE_NUMBER}}</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              hide-details
              dense>
            </v-text-field>
          </v-col>
        </v-row>
        <date-and-time
          label="Tanggal dan Jam Keberangkatan"
          class="mt-3 caption"
          date="estimateDepartureDate"
          time="estimateDepartureTime"
          :tools="toolsForm"
          :requiredDate="true"
          :requiredTime="true"
        ></date-and-time>
        <date-and-time
          class="caption mt-1"
          label="Tanggal dan Jam Kedatangan"
          date="estimateArrivalDate"
          time="estimateArrivalTime"
          :tools="toolsForm"
          :requiredDate="true"
          :requiredTime="true"
        ></date-and-time>
      </v-form>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    toolsForm: {
      type: Object,
      default: () => {},
    },
    formChecklist: {
      type: Object,
      default: () => {},
    },
    itemsCargo: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    'formChecklist.checkGroupToShipment': function setCheckGroupToShipment(newVal) {
      if (!newVal) {
        this.form.groupToShipmentCargo = '';
        return;
      }
      if (this.shipment.cargoGroupNumber) {
        const lengthString = this.shipment.cargoGroupNumber.split('').length;
        const newCargoNumber = this.shipment.cargoGroupNumber.substring(1, lengthString);
        this.form.groupToShipmentCargo = newCargoNumber;
      }
    },
    'formChecklist.checkContinueFromShipmentCargo': function setContinueFromShipmentCargo(newVal) {
      if (!newVal) {
        this.form.nextFromShipmentCargo = [];
        return;
      }
      if (this.shipment.nextFromShipmentCargo && this.shipment.nextFromShipmentCargo.length) {
        this.form.nextFromShipmentCargo = this.shipment.nextFromShipmentCargo;
      }
    },
  },
};
</script>
