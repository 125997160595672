<template>
  <v-sheet>
    <v-row class="pl-4 caption align-center">
      <v-col cols="auto"><span>Legend:</span></v-col>
      <v-col v-if="status === 'unconfirmed'" cols="auto">
        <v-icon color="red">
          mdi-help-circle-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.HAVE_NOT_BEEN_ASSIGNED}}</span>
      </v-col>
      <v-col v-if="status === 'unconfirmed'" cols="auto">
        <v-icon color="yellow accent-4">
          mdi-alert-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.PARTIALLY_ASSIGNED}}</span>
      </v-col>
      <v-col  v-if="status === 'unconfirmed'" cols="auto">
        <v-icon color="primary">
          mdi-circle-edit-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.ASSIGN1}}</span>
      </v-col>
      <!-- TAB 1 -->
      <v-col  v-if="status === 'process'" cols="auto">
        <v-icon color="primary">
          mdi-circle-edit-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.UPDATE_INFORMATION}}</span>
      </v-col>
      <v-col v-if="status === 'process'" cols="auto">
        <v-icon color="primary">
          mdi-check-circle-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.COMPLATE_THE_CARGO}}</span>
      </v-col>
      <v-col v-if="status === 'process'" cols="auto">
        <v-icon color="red">
          mdi-close-circle-outline
        </v-icon>
        <span class="ml-2">{{$_strings.order.CANCEL_THE_ORDER}}</span>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

export default {
  name: 'footer-order',
  props: {
    status: String,
  },
};
</script>
