<template>
  <div>
    <common-error
      v-if="isError"
      @fetchData="$emit('fetchData')"
    />
    <v-data-table
      v-else
      calculate-widths
      hide-default-footer
      class="elevation-1"
      :loading="isLoading"
      :headers="headersCombinedOrders"
      :items="combinedItems.length > 1 ? [...combinedItems, {
        footer: true
      }] : combinedItems"
    >
      <template v-slot:[`item.checkbox`]={item}>
        <v-checkbox
          v-if="!item.footer"
          @change="(e) => handleCheckbox(e, item, 'combinedItems')"
          v-model="item.checkbox"
        >
        </v-checkbox>
      </template>
      <template v-slot:[`item.title`]={item}>
        <span class="font-weight-bold" v-if="item.footer">Total</span>
        <span v-else>{{item.title}}</span>
      </template>
      <template v-slot:[`item.pickupDate`]={item}>
        <span v-if="item.pickupDate">{{dateFormat(item.pickupDate)}} {{timeFormat(item.pickupDate)}}</span>
      </template>
      <template v-slot:[`item.tonase`]={item}>
        <span class="font-weight-bold" v-if="item.footer">{{tonaseTotal}}</span>
        <span v-else>{{item.tonase}}</span>
      </template>
      <template v-slot:[`item.cubic`]={item}>
        <span class="font-weight-bold" v-if="item.footer">{{cubicTotal}}</span>
        <span v-else>{{item.cubic}}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    combinedItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    handleCheckbox: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      headersCombinedOrders: [
        {
          text: this.$_strings.order.SELECT,
          value: 'checkbox',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORDER_NUMBER,
          value: 'title',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.SEND_DATE,
          value: 'pickupDate',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocation.name',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation.name',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.TONASE,
          value: 'tonase',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.KUBIKASI,
          value: 'cubic',
          class: 'white--text primary',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    checkedItems() {
      return this.combinedItems.filter((x) => x.checkbox);
    },
    tonaseTotal() {
      const total = this.checkedItems.reduce((current, item) => +item.tonase + current, 0);
      return this.twoDigitComma(total);
    },
    cubicTotal() {
      const total = this.checkedItems.reduce((current, item) => +item.cubic + current, 0);
      return this.twoDigitComma(total);
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    twoDigitComma(number) {
      const splitNumber = String(number).split('.');
      let _number = number;
      if (splitNumber.length > 1) {
        if (splitNumber[1].length > 2) {
          _number = parseFloat(number).toFixed(2);
        }
      }
      return parseFloat(_number);
    },
  },
};
</script>
