<template>
  <v-menu
    top
    :close-on-content-click="closeOnContentClick"
    offset-y
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :loading="isLoading"
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        placeholder="Filter Provinsi"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false"
        v-on="on"
        :disabled="isError"
      ></v-text-field>
    </template>
    <v-list
      max-height="500"
      class="overflow-auto"
    >
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">{{$_strings.order.FILTER_PROVINSI_ORIGIN}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-list>
              <div v-for="(origin, index) in originLocationProvinceIds" :key="origin">
                <v-chip
                  class="mb-2 caption"
                  close
                  color="grey lighten-2"
                  label
                  text-color="black"
                  @click:close="removeOriginProvince(index)"
                >
                  {{provinceList.find((prov) => prov.id === origin).name}}
                </v-chip>
              </div>
              <v-autocomplete
                :items="originLocationProvinceItems"
                v-model="origin"
                @change="(event) => pushOriginLocationProvince(event)"
                hide-details
                item-text="name"
                item-value="id"
                class="caption"
                placeholder="Provinsi Asal"
                dense
              ></v-autocomplete>
            </v-list>
          </v-list-item-content>
        </v-list-item>
      </section>
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">{{$_strings.order.FILTER_PROVINSI_DESTINATION}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-list>
              <div v-for="(destination, index) in destinationLocationProvinceIds" :key="destination">
                <v-chip
                  class="mb-2 caption"
                  close
                  color="grey lighten-2"
                  label
                  text-color="black"
                  @click:close="removeDestinationProvince(index)"
                >
                  {{provinceList.find((prov) => prov.id === destination).name}}
                </v-chip>
              </div>
              <v-autocomplete
                :items="destinationLocationProvinceItems"
                v-model="destination"
                @change="(event) => pushDestinationLocationProvince(event)"
                hide-details
                item-text="name"
                item-value="id"
                class="caption"
                placeholder="Provinsi Tujuan"
                dense
              ></v-autocomplete>
            </v-list>
          </v-list-item-content>
        </v-list-item>
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          :disabled="!originLocationProvinceIds.length || !destinationLocationProvinceIds.length"
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          {{$_strings.common.APPLY}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    provinceList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.originLocationProvinceIds = [];
        this.destinationLocationProvinceIds = [];
        this.origin = -2;
        this.destination = -2;
      }
    },
  },
  data() {
    return {
      closeOnContentClick: false,
      originLocationProvinceIds: [],
      destinationLocationProvinceIds: [],
      origin: 0,
      destination: 0,
    };
  },
  computed: {
    originLocationProvinceItems() {
      return this.provinceList.filter((prov) => !this.originLocationProvinceIds.includes(prov.id));
    },
    destinationLocationProvinceItems() {
      return this.provinceList.filter((prov) => !this.destinationLocationProvinceIds.includes(prov.id));
    },
  },
  methods: {
    pushOriginLocationProvince(event) {
      this.origin = 0;
      this.originLocationProvinceIds.push(event);
    },
    removeOriginProvince(index) {
      this.origin = -1;
      this.originLocationProvinceIds.splice(index, 1);
    },
    pushDestinationLocationProvince(event) {
      this.destination = 0;
      this.destinationLocationProvinceIds.push(event);
    },
    removeDestinationProvince(index) {
      this.destination = -1;
      this.destinationLocationProvinceIds.splice(index, 1);
    },
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('fetchWantCombine', this.originLocationProvinceIds, this.destinationLocationProvinceIds);
    },
  },
};
</script>
