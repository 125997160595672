var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isError)?_c('common-error',{on:{"fetchData":function($event){return _vm.$emit('fetchData')}}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"calculate-widths":"","hide-default-footer":"","loading":_vm.isLoading,"headers":_vm.headersCombinedOrders,"items":_vm.combinedItems.length > 1 ? _vm.combinedItems.concat( [{
      footer: true
    }]) : _vm.combinedItems},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
    var item = ref.item;
return [(!item.footer)?_c('v-checkbox',{on:{"change":function (e) { return _vm.handleCheckbox(e, item, 'combinedItems'); }},model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}}):_vm._e()]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [(item.footer)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Total")]):_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.pickupDate",fn:function(ref){
    var item = ref.item;
return [(item.pickupDate)?_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.pickupDate))+" "+_vm._s(_vm.timeFormat(item.pickupDate)))]):_vm._e()]}},{key:"item.tonase",fn:function(ref){
    var item = ref.item;
return [(item.footer)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.tonaseTotal))]):_c('span',[_vm._v(_vm._s(item.tonase))])]}},{key:"item.cubic",fn:function(ref){
    var item = ref.item;
return [(item.footer)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.cubicTotal))]):_c('span',[_vm._v(_vm._s(item.cubic))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }