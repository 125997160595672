<template>
  <v-dialog
    :key="dialog"
    v-model="dialog"
    persistent
    scrollable
    width="1200"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="caption py-0">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" class="pb-1">
              <h3>{{$_strings.order.CONFIRMATION_DATE}}</h3>
            </v-col>
            <v-col class="pt-0">
              <v-row>
                <v-col class="pb-0" cols="auto">
                  <v-menu
                    ref="menuCofirmDate"
                    v-model="menuCofirmDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    class="caption"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        class="caption"
                        :value="form.confirmationDate && dateFormat(form.confirmationDate)"
                        placeholder="Tanggal"
                        prepend-inner-icon="mdi-calendar"
                        :rules="rules.date"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.confirmationDate"
                      @input="menuCofirmDate = false"
                      :min="minDate()"
                      @change="handleDate"
                      no-title
                      scrollable
                      locale="id"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="auto">
                  <common-time-picker
                    ref="time"
                    placeholder="Jam"
                    v-model="time"
                    @input="setPickupDate"
                    :value="time && timeFormat(time)"
                    outlined
                    dense
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    bindClass="caption"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-title>
      <v-card-text class="px-4">
        <v-row class="pa-3">
          <v-col cols="12" md="6">
            <h3>Pilih Kendaraan</h3>
            <v-sheet
              class="my-2 rounded"
              color="grey lighten-5"
              :height="$vuetify.breakpoint.smAndUp ? '390' : '800'"
              style="position:relative"
            >
              <vehicle :form="form" ref="refVehicle"/>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6">
            <h3>Pilih Sopir</h3>
            <v-sheet
              class="my-2 rounded"
              color="grey lighten-5"
              :min-height="$vuetify.breakpoint.smAndUp ? '380' : '800'"
              style="position:relative"
            >
              <driver :form="form" ref="refDriver" @setDriver="setDriver"/>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          :disabled="!form.transportId || !form.companyDriverId"
          @click="submit"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { getDateTimeTz, dateFormat, timeFormat } from '@/helper/commonHelpers';
import axios from 'axios';
import Driver from './Driver.vue';
import Vehicle from './Vehicle.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    Driver,
    Vehicle,
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      menuCofirmDate: false,
      isAutoAssign: false,
      rules: {
        date: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Tanggal'),
        ],
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          return true;
        },
      },
      time: null,
      shipment: {},
      form: {
        confirmationDate: null,
        statusDatetimeTz: this.getDateTimeTz(),
        shipmentCargoId: null,
        companyDriverId: null,
        transportId: null,
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.isAutoAssign) {
          const {
            groupToShipmentCargo,
            nextFromShipmentCargo,
            continueFromShipmentCargo,
            isLumpSum,
            shipmentsCargoId,
            transporterCompanyId,
            mouRouteId,
          } = this.shipment;
          this.form = {
            groupToShipmentCargo,
            nextFromShipmentCargo,
            continueFromShipmentCargo,
            isLumpSum,
            shipmentsCargoId,
            transporterCompanyId,
            mouRouteId,
            ...this.form,
          };
        }
        source = CancelToken.source();
      }
      if (!newVal) {
        this.isAutoAssign = false;
        this.time = null;
        this.form = {
          confirmationDate: null,
          statusDatetimeTz: this.getDateTimeTz(),
          shipmentCargoId: null,
          companyDriverId: null,
          transportId: null,
        };
        source.cancel('CANCELED_BY_THE_USER');
      }
    },
    'form.transportId': function transportId() {
      const findVehicle = this.$refs.refVehicle.listVehicle.find((vehicle) => vehicle.id === this.form.transportId);
      if (findVehicle) {
        const findDriver = this.$refs.refDriver.listDrivers.find((driver) => driver.id === findVehicle.companyDriverId);
        if (!findDriver) {
          this.form.companyDriverId = null;
          const event = {
            target: {
              value: findVehicle.driverName,
            },
          };
          this.$refs.refDriver.pagination.name = findVehicle.driverName;
          this.$refs.refDriver.fetchData(event);
          return;
        }
        this.form.companyDriverId = findDriver.id;
      }
    },
  },
  methods: {
    getDateTimeTz,
    dateFormat,
    timeFormat,
    minDate() {
      if (this.shipment.pickupDate) return dayjs(this.shipment.pickupDate).format('YYYY-MM-DD');
      return null;
    },
    setPickupDate(time) {
      const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (this.form.confirmationDate && timePattern.test(time)) {
        const date = dayjs(this.form.confirmationDate).format('YYYY-MM-DD');
        const confirmationDate = dayjs(`${date} ${time}`).format();
        this.form.confirmationDate = confirmationDate;
      }
    },
    handleDate() {
      this.setPickupDate(this.time);
      this.$refs.time.$refs['time-input'].focus();
    },
    setDriver() {
      const findVehicle = this.$refs.refVehicle.listVehicle.find((vehicle) => vehicle.id === this.form.transportId);
      if (findVehicle) {
        const findDriver = this.$refs.refDriver.listDrivers.find((driver) => driver.id === findVehicle.companyDriverId);
        if (!findDriver) {
          this.form.companyDriverId = null;
          return;
        }
        this.form.companyDriverId = findDriver.id;
      }
    },
    async submitAutoAssign() {
      try {
        const isValid = this.$refs.form.validate();
        if (!isValid) return this.$dialog.notify.error('Harap lengkapi tanggal konfirmasi');
        this.isLoading = true;
        await this.$_services.order.autoAssign(this.form, source);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchOrders');
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      if (this.isAutoAssign) return this.submitAutoAssign();
      try {
        const isValid = this.$refs.form.validate();
        if (!isValid) return this.$dialog.notify.error('Harap lengkapi tanggal konfirmasi');
        this.isLoading = true;
        await this.$_services.order.assignDriver(this.form, source);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchOrders');
        this.$emit('refreshDataOnPageUnconfirmed');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
