var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$_strings.order.VEHICLE_INFO))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"headers":_vm.displayedHeaders,"calculate-widths":"","hide-default-footer":"","items":_vm.items,"items-per-page":100},scopedSlots:_vm._u([{key:"item.pickupDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.pickupDate)))]),_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.pickupDate)))])]}},{key:"item.estimateArrival",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.estimateArrival ? _vm.dateFormat(item.estimateArrival) : '-'))]),(item.estimateArrival)?_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.estimateArrival)))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0 pa-0",class:_vm.getColorStatus(item.status)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","icon":"","disabled":!_vm.statusAllowedToAssign3pl.includes(item.status.toLowerCase())},on:{"click":function($event){return _vm.showDialogAssign3PL(item)}}},[_c('v-icon',[_vm._v(" mdi-circle-edit-outline ")])],1)]}}],null,true)})],1)],1),_c('DialogAssign3PL',{ref:"dialogAssign3PL",on:{"showDialogDriverAndTransport":_vm.showDialogDriverAndTransport,"handleAfterAssign3PL":_vm.handleAfterAssign3PL}}),_c('DialogAssign3PLLcl',{ref:"dialogAssign3PLLcl",on:{"showDialogDriverAndTransport":_vm.showDialogDriverAndTransport,"handleAfterAssign3PL":_vm.handleAfterAssign3PL}}),_c('DialogVehicle',{ref:"dialogVehicle",on:{"fetchOrders":function($event){return _vm.$emit('fetchOrders')},"refreshDataOnPageUnconfirmed":function () {}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }