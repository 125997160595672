<template>
  <div class="text-center d-flex">
    <v-menu
      bottom
      :close-on-content-click="closeOnContentClick"
      width="300"
      offset-y
      z-index="1"
      allow-overflow
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          hide-details
          height="10"
          class="caption"
          prepend-inner-icon="mdi-filter"
          :placeholder="$_strings.order.FILTER"
          outlined
          dense
          readonly
          v-bind="attrs"
          @click="closeOnContentClick = false"
          v-on="on"
        ></v-text-field>
      </template>
      <v-list
        width="300"
        min-height="150"
        max-height="500"
        class="overflow-auto"
      >
        <section v-for="item in itemsFilterAutoComplete" :key="item.id">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">{{item.title}}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <common-auto-complete-items
                :type="item.type"
                :searchName="item.searchName"
                :item-value="item.value"
                :item-text="item.text"
                hide-details
                height="10"
                v-model="form[item.vModel]"
                :placeholder="item.placeholder"
                dense
                outlined
                class="caption"
                clearable
                @updateItems="item.updateItems"
                :filter="pageFilters[item.filters]"
                :items="itemsFilter[item.items]"
              />
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-if="!$route.params.status.includes('confirmation')">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Nomor Kendaraan</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-tooltip v-model="showTooltipLicensePlate" bottom>
                <template v-slot:activator="{ }">
                  <v-text-field
                    dense
                    outlined
                    class="caption"
                    placeholder="A 1234 BC"
                    v-model="form.licensePlate"
                    hide-details
                    @input="handleTooltipLicensePlate"
                    @blur="showTooltipLicensePlate = false"
                  />
                </template>
                <p>Ex: A 1234 BC</p>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-if="!$route.params.status.includes('confirmation')">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Keterangan Tarif</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <v-select
                v-model="form.serviceType"
                :items="itemsSeriviceTypes"
                class="caption"
                placeholder="Pilih Keterangan Tarif"
                hide-details
                dense
                clearable
                outlined
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-if="!$route.params.status.includes('confirmation')">
          <v-list-item>
            <v-list-item-title class="caption font-weight-bold">Status</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pt-0 mt-0">
              <section
                v-for="(chip, index) in latestStatus"
                :key="chip"
                class="mb-2"
              >
                <v-chip
                  close
                  small
                  @click:close="removeLatestStatus(index)"
                >
                  <strong>{{ itemsLatestStatus.find((item) => item.value === chip) && itemsLatestStatus.find((item) => item.value === chip).text }}</strong>
                </v-chip>
              </section>
              <v-autocomplete
                hide-details
                :items="itemsLatestStatus.filter((item) => !(latestStatus.find((ls) => ls === item.value)))"
                @change="addLatestStatus"
                v-model="status"
                class="caption"
                placeholder="Pilih status"
                dense
              ></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-if="$route.params.status === 'process' || $route.params.status === 'finished'">
          <v-list-item class="mb-0">
            <v-list-item-title class="caption font-weight-bold pb-0">Quantity Status</v-list-item-title>
          </v-list-item>
          <v-list-item class="pt-0">
            <v-list-item-content class="pt-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="calculatedQty"
                    @change="setIsCalculatedQty('calculatedQty')"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">Sudah diinput</span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="notCalculatedQty"
                    @change="setIsCalculatedQty('notCalculatedQty')"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">Belum diinput</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-if="$route.params.status === 'finished'">
          <v-list-item class="mb-0">
            <v-list-item-title class="caption font-weight-bold pb-0">Calculation Status</v-list-item-title>
          </v-list-item>
          <v-list-item class="pt-0">
            <v-list-item-content class="pt-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="calculated"
                    @change="setIsCalculated('calculated')"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">Sudah dihitung</span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="notCalculated"
                    @change="setIsCalculated('notCalculated')"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">Belum dihitung</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="mb-0">
            <v-list-item-title class="caption font-weight-bold pb-0">POD Status</v-list-item-title>
          </v-list-item>
          <v-list-item class="pt-0">
            <v-list-item-content class="pt-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="pod"
                    @change="setIsPod('pod')"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">Sudah POD</span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    hide-details
                    class="caption pt-0 mt-0"
                    v-model="notPod"
                    @change="setIsPod('notPod')"
                  >
                    <template v-slot:label>
                      <span class="caption" id="checkboxLabel">Belum POD</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section class="pl-2 pr-2">
          <v-btn
            depressed
            color="primary"
            block
            @click="setFilter"
          >
            {{$_strings.common.APPLY}}
          </v-btn>
          <v-btn
            depressed
            class="mt-2 white--text"
            color="red"
            block
            @click="closeOnContentClick = true"
          >
            {{$_strings.common.CLOSE}}
          </v-btn>
        </section>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    itemsFilter: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    statusOrder() {
      const path = this.$route.path.split('/');
      const status = path[path.length - 1];
      return status;
    },
    itemslistStatus() {
      switch (this.statusOrder) {
        case 'unconfirmed':
          return this.$_strings.order.STATUS_LIST_WAITING;
        case 'process':
          return this.$_strings.order.STATUS_LIST_PROCESS;
        case 'finished':
          return this.$_strings.order.STATUS_LIST_COMPLETED;
        default:
          return [];
      }
    },
  },
  mounted() {
    this.setListStatus();
    this.setItemsFromQueryString();
    if (this.$route.query.isCalculatedQty === 'true') {
      this.form.hasQuantityDelivery = true;
      this.filters.isCalculatedQty = true;
    }
    if (this.$route.query.isCalculatedQty === 'false') {
      this.form.hasQuantityDelivery = false;
      this.filters.isCalculatedQty = false;
    }
    if (this.$route.query.isCalculated === 'true') {
      this.form.isCalculated = true;
      this.filters.isCalculated = true;
    }
    if (this.$route.query.isCalculated === 'false') {
      this.form.isCalculated = false;
      this.filters.isCalculated = false;
    }
    if (this.$route.query.isPod === 'true') {
      this.form.isPod = true;
      this.filters.isPod = true;
    }
    if (this.$route.query.isPod === 'false') {
      this.form.isPod = false;
      this.filters.isPod = false;
    }
  },
  data() {
    return {
      status: '',
      calculatedQty: this.$route.query.hasQuantityDelivery === 'true',
      notCalculatedQty: this.$route.query.hasQuantityDelivery === 'false',
      calculated: this.$route.query.isCalculated === 'true',
      notCalculated: this.$route.query.isCalculated === 'false',
      pod: this.$route.query.isPod === 'true',
      notPod: this.$route.query.isPod === 'false',
      itemsFilterAutoComplete: [
        {
          id: 'customer',
          title: 'Nama Shipper',
          type: 'masterShipper',
          searchName: 'abbreviation',
          placeholder: 'Semua Shipper',
          text: 'abbreviation',
          value: 'id',
          vModel: 'shipperId',
          items: 'itemsCustomers',
          updateItems: this.updateCustomerItems,
          filters: 'customer',
        },
        {
          id: 'transporter',
          title: 'Rekanan 3PL',
          type: 'masterPartner',
          searchName: 'companyName',
          placeholder: 'Semua Rekanan 3PL',
          text: 'companyName',
          value: 'id',
          vModel: 'transporterId',
          items: 'itemsTransporter',
          updateItems: this.updateTransporterItems,
          filters: 'transporter',
        },
        {
          id: 'origin',
          title: 'Lokasi Asal',
          type: 'locations',
          searchName: 'name',
          placeholder: 'Semua Lokasi Asal',
          text: 'name',
          value: 'id',
          vModel: 'originId',
          items: 'itemsLocation',
          updateItems: this.updateLocation,
          filters: 'location',
        },
        {
          id: 'destination',
          title: 'Lokasi Tujuan',
          type: 'locations',
          searchName: 'name',
          placeholder: 'Semua Lokasi Tujuan',
          text: 'name',
          value: 'id',
          vModel: 'destinationId',
          items: 'itemsLocation',
          updateItems: this.updateLocation,
          filters: 'location',
        },
      ],
      itemsLatestStatus: [],
      itemsSeriviceTypes: [
        {
          text: 'FCL',
          value: 'FCL',
        },
        {
          text: 'FCL BACKHAULING',
          value: 'FCL_BACKHAULING',
        },
        {
          text: 'LCL',
          value: 'LCL',
        },
      ],
      form: {
        shipperId: +this.$route.query.shipperId || '',
        transporterId: +this.$route.query.transporterId || '',
        originId: +this.$route.query.originId || '',
        destinationId: +this.$route.query.destinationId || '',
        licensePlate: this.$route.query.licensePlate || '',
        hasQuantityDelivery: '',
        isCalculated: '',
        isPod: '',
        serviceType: this.$route.query.serviceType || '',
      },
      closeOnContentClick: false,
      latestStatus: this.$route.query.latestStatus ? this.$route.query.latestStatus.split(',') : [],
      showTooltipLicensePlate: false,
    };
  },
  methods: {
    handleTooltipLicensePlate(value) {
      const pattern = /^([A-Za-z]{1,4})([\s]{1})([0-9]{1,5})([\s]{1})([A-Za-z]{0,3})$/;
      if (!pattern.test(value) && value) {
        this.showTooltipLicensePlate = true;
        return;
      }
      this.showTooltipLicensePlate = false;
    },
    setIsCalculatedQty(value) {
      if (value === 'calculatedQty' && this.notCalculatedQty) this.notCalculatedQty = false;
      if (value === 'notCalculatedQty' && this.calculatedQty) this.calculatedQty = false;
      if (!this.notCalculatedQty && !this.calculatedQty) {
        this.form.hasQuantityDelivery = '';
        return;
      }
      if (this.notCalculatedQty) {
        this.form.hasQuantityDelivery = false;
        return;
      }
      this.form.hasQuantityDelivery = true;
    },
    setIsCalculated(value) {
      if (value === 'calculated' && this.notCalculated) this.notCalculated = false;
      if (value === 'notCalculated' && this.calculated) this.calculated = false;
      if (!this.notCalculated && !this.calculated) {
        this.form.isCalculated = '';
        return;
      }
      if (this.notCalculated) {
        this.form.isCalculated = false;
        return;
      }
      this.form.isCalculated = true;
    },
    setIsPod(value) {
      if (value === 'pod' && this.notPod) this.notPod = !this[value];
      if (value === 'notPod' && this.pod) this.pod = !this[value];
      if (!this.notPod && !this.pod) {
        this.form.isPod = '';
        return;
      }
      if (this.notPod) {
        this.form.isPod = false;
        return;
      }
      this.form.isPod = true;
    },
    setItemsFromQueryString() {
      const {
        shipperId,
        shipperName,
        transporterId,
        transporterName,
        originId,
        origin,
        destinationId,
        destination,
      } = this.$route.query;
      if (shipperId) {
        if (!(this.itemsFilter.itemsCustomers.find((item) => item.id === +shipperId))) {
          this.itemsFilter.itemsCustomers.push({
            id: +shipperId,
            abbreviation: shipperName,
          });
        }
      }
      if (transporterId) {
        if (!(this.itemsFilter.itemsTransporter.find((item) => item.id === +transporterId))) {
          this.itemsFilter.itemsTransporter.push({
            id: +transporterId,
            companyName: transporterName,
          });
        }
      }
      if (originId) {
        if (!(this.itemsFilter.itemsLocation.find((item) => item.id === +originId))) {
          this.itemsFilter.itemsLocation.push({
            id: +originId,
            name: origin,
          });
        }
      }
      if (destinationId) {
        if (!(this.itemsFilter.itemsLocation.find((item) => item.id === +destinationId))) {
          this.itemsFilter.itemsLocation.push({
            id: +destinationId,
            name: destination,
          });
        }
      }
    },
    setListStatus() {
      switch (this.statusOrder) {
        case 'unconfirmed':
          this.itemsLatestStatus = this.$_strings.order.STATUS_LIST_WAITING;
          break;
        case 'process':
          this.itemsLatestStatus = this.$_strings.order.STATUS_LIST_PROCESS;
          break;
        case 'finished':
          this.itemsLatestStatus = this.$_strings.order.STATUS_LIST_COMPLETED;
          break;
        default:
          return [];
      }
    },
    updateCustomerItems(items) {
      this.itemsFilter.itemsCustomers = [...this.itemsFilter.itemsCustomers, ...items];
    },
    updateTransporterItems(items) {
      this.itemsFilter.itemsTransporter = [...this.itemsFilter.itemsTransporter, ...items];
    },
    updateLocation(items) {
      this.itemsFilter.itemsLocation = [...this.itemsFilter.itemsLocation, ...items];
    },
    addLatestStatus(value) {
      this.latestStatus.push(value);
    },
    removeLatestStatus(index) {
      this.latestStatus.splice(index, 1);
      this.status = '';
    },
    setFilter() {
      this.closeOnContentClick = true;
      const {
        shipperId,
        transporterId,
        originId,
        destinationId,
        licensePlate,
        isCalculated,
        hasQuantityDelivery,
        isPod,
        serviceType,
      } = this.form;
      const oldFilters = { ...this.filters };
      this.filters.shipperId = shipperId;
      this.filters.transporterId = transporterId;
      this.filters.originId = originId;
      this.filters.destinationId = destinationId;
      this.filters.licensePlate = licensePlate;
      this.filters.serviceType = serviceType;
      this.filters.latestStatus = this.latestStatus.toString();
      this.filters.isCalculated = '';
      this.filters.hasQuantityDelivery = '';
      this.filters.isPod = '';
      if (typeof hasQuantityDelivery === 'boolean') {
        this.filters.hasQuantityDelivery = hasQuantityDelivery;
      }
      if (typeof isCalculated === 'boolean') {
        this.filters.isCalculated = isCalculated;
      }
      if (typeof isPod === 'boolean') {
        this.filters.isPod = isPod;
      }
      if (JSON.stringify(oldFilters) !== JSON.stringify(this.filters)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...this.filters,
            origin: originId ? this.itemsFilter.itemsLocation.find((item) => item.id === +originId).name : '',
            destination: destinationId ? this.itemsFilter.itemsLocation.find((item) => item.id === +destinationId).name : '',
            shipperName: shipperId ? this.itemsFilter.itemsCustomers.find((item) => item.id === +shipperId).abbreviation : '',
            transporterName: transporterId ? this.itemsFilter.itemsTransporter.find((item) => item.id === +transporterId).companyName : '',
            page: 1,
          },
        });
      }
      this.$parent.$parent.$emit('fetching');
    },
  },
};
</script>
