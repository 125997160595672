<template>
  <section>
    <v-row>
      <v-col><h4 class="pl-2">{{$_strings.order.MULTIMODA_INFO}}</h4></v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          :headers="displayedHeaders"
          calculate-widths
          hide-default-footer
          :items="items"
          class="grey lighten-4"
          :items-per-page="100"
        >
          <template v-slot:[`item.pickupDate`]={item}>
            <p class="ma-0">{{dateFormat(item.pickupDate)}}</p>
            <p>{{timeFormat(item.pickupDate)}}</p>
          </template>
          <template v-slot:[`item.status`]={item}>
            <p
              class="ma-0 pa-0"
              :class="getColorStatus(item.status)">
              {{item.status}}
            </p>
          </template>
          <template v-slot:[`item.action`]={item}>
            <v-btn
              v-if="showBtnBap(item)"
              color="primary"
              small
              icon
              @click="navigateToMultimodaBasp(item)"
            >
              <v-icon>
                mdi-circle-edit-outline
              </v-icon>
            </v-btn>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="handleEditMultimoda(item)"
                  :disabled="disableBtnAssignMultimoda(item.status.toLowerCase())"
                  color="primary"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-circle-edit-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  statusAllowedToAssign3pl.includes(item.status.toLowerCase()) ?
                    $_strings.order.ASSIGN_3PL :
                    $_strings.common.UPDATE
                }}
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogAssign3PL
      @handleAfterAssign3PL="handleAfterAssign3PL"
      ref="dialogAssign3PL"
    />
    <DialogAssign3PLLcl
      @handleAfterAssign3PL="handleAfterAssign3PL"
      ref="dialogAssign3PLLcl"
    />
    <DialogUpdateMultimoda
      @handleAfterAssign3PL="handleAfterAssign3PL"
      ref="dialogUpdateMultimoda"
    />
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '../../../helper/commonHelpers';
import RenderFunction from './function';
import DialogAssign3PL from '../Dialog/Assign3PL.vue';
import DialogAssign3PLLcl from '../Dialog/Assign3plLcl';
import DialogUpdateMultimoda from '../Dialog/UpdateMultimoda';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogAssign3PL,
    DialogAssign3PLLcl,
    DialogUpdateMultimoda,
  },
  data() {
    return {
      statusAllowedToAssign3pl: [
        'waktu habis',
        'pesanan dibuat',
        'ditolak',
      ],
      statusBAP: [
        'ba konfirmasi',
        'bap - konfirmasi',
        'bap - konfirmasi shipper',
        'ba penerimaan - kembali ke gudang asal',
        'ba penerimaan - ganti lokasi tujuan',
        'ba penerimaan konfirmasi',
      ],
    };
  },
  computed: {
    isRouteUpdateOrder() {
      return this.$route.path.split('/')[2] === 'update-orders';
    },
    showBtnAction() {
      const showButtonInPage = ['unconfirmed', 'process'];
      const { status } = this.$route.params;
      const { canUpdate } = this.userAccess;
      return canUpdate && showButtonInPage.includes(status);
    },
    displayedHeaders() {
      const renderFunction = new RenderFunction(this);
      return renderFunction.tableHeader('vehicle').filter((header) => {
        if (header.value === 'estimateArrival') return;
        if (header.value === 'action' && !this.showBtnAction) return;
        return header;
      });
    },
    items() {
      const routeItems = [];
      this.item.subOrder.routes.forEach((route) => {
        // CHECK DUPLICATE CARGO NUMBER OR PUSH IF CARGO NUMBER IS NULL AND ROUTE TYPE IS NOT AIR
        if (
          (
            !routeItems.find((findRoute) => findRoute.cargoNumber === route.cargoNumber)
            || !route.cargoNumber
          )
          && route.routeType !== 'LAND' // AIR AND SEA
        ) {
          return routeItems.push(route);
        }
      });
      return routeItems;
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    getColorStatus(status) {
      const _status = status.toLowerCase();
      const redStatus = ['dibatalkan', 'ditolak', 'kedaluwarsa', 'dokumen tidak lengkap'];
      const greenStatus = ['barang diterima utuh', 'barang diterima tidak utuh'];
      if (redStatus.includes(_status)) return 'red--text';
      if (greenStatus.includes(_status)) return 'green--text';
      return 'black--text';
    },
    showBtnBap(item) {
      return this.statusBAP.includes(item.status.toLowerCase());
    },
    showDialogMultimoda(item) {
      this.$refs.dialogUpdateMultimoda.shipment = item;
      this.$refs.dialogUpdateMultimoda.dialog = true;
    },
    disableBtnAssignMultimoda(status) {
      if (!this.isRouteUpdateOrder) return !this.statusAllowedToAssign3pl.includes(status);
      return false;
    },
    handleEditMultimoda(item) {
      if (this.statusAllowedToAssign3pl.includes(item.status.toLowerCase())) {
        return this.showDialogAssign3PL(item);
      }
      if (this.isRouteUpdateOrder) this.showDialogMultimoda(item);
    },
    showDialogAssign3PL(item) {
      const itemsCargo = this.item.subOrder.routes.map((x) => ({
        cargoNumber: x.cargoNumber,
      }));
      if (item.serviceType === 'LCL') {
        this.$refs.dialogAssign3PLLcl.itemsCargo = itemsCargo;
        this.$refs.dialogAssign3PLLcl.shipment = item;
        this.$refs.dialogAssign3PLLcl.dialog = true;
        return;
      }
      this.$refs.dialogAssign3PL.shipment = item;
      this.$refs.dialogAssign3PL.itemsCargo = itemsCargo;
      this.$refs.dialogAssign3PL.dialog = true;
    },
    handleAfterAssign3PL() {
      const { status } = this.$route.params;
      this.$delete(this.item, 'subOrder');
      this.$emit('fetchSubOrder', this.item);
      if (status === 'unconfirmed') {
        this.$emit('fetchNewLegend', this.item);
        this.$emit('isHasWaitingOrder');
      }
    },
    navigateToMultimodaBasp(item) {
      let shipments = {};
      item.shipments.forEach((i) => {
        const findOrder = this.item.subOrder.shipmentResponse.find((_order) => _order.id === i.shipmentsId);
        if (findOrder) shipments = findOrder;
      });
      const data = {
        ...item,
        shipments,
      };
      this.$router.push({
        name: 'multimoda-basp',
        params: {
          data,
        },
      });
    },
  },
};
</script>
