<template>
  <v-data-table
    :headers="headersIsNotCombinedOrders"
    :items="notCombinedItems"
    :loading="isLoading"
    calculate-widths
    hide-default-footer
    class="elevation-1"
    item-key="_id"
  >
    <template v-slot:[`item.checkbox`]={item}>
      <v-checkbox
        @change="(e) => handleCheckbox(e, item, 'notCombinedItems')"
        v-model="item.checkbox"
      >
      </v-checkbox>
    </template>
    <template v-slot:[`item.pickupDate`]={item}>
      <span v-if="item.pickupDate">{{dateFormat(item.pickupDate)}} {{timeFormat(item.pickupDate)}}</span>
    </template>
  </v-data-table>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    notCombinedItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    handleCheckbox: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      headersIsNotCombinedOrders: [
        {
          text: this.$_strings.order.SELECT,
          value: 'checkbox',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORDER_NUMBER,
          value: 'title',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.SEND_DATE,
          value: 'pickupDate',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocation.name',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation.name',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.TONASE,
          value: 'tonase',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.KUBIKASI,
          value: 'cubic',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      checkbox: false,
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    twoDigitComma(number) {
      const splitNumber = String(number).split('.');
      let _number = number;
      if (splitNumber.length > 1) {
        if (splitNumber[1].length > 2) {
          _number = parseFloat(number).toFixed(2);
        }
      }
      return parseFloat(_number);
    },
  },
};
</script>
