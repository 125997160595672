<template>
  <v-card :key="isFetchData">
    <v-toolbar
      flat
      color="primary"
      dark
      :height="$vuetify.breakpoint.smAndUp ? '60px' : '120px'"
    >
      <v-row align="center">
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="auto">
              <h2>3PL</h2>
            </v-col>
            <v-col>
              <v-checkbox v-model="form.isLumpSum" hide-details>
                <template v-slot:label>
                  <div class="caption grey black--text pa-1 rounded">{{$_strings.order.MAKE_LUMPSUM}}</div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" >
          <v-spacer></v-spacer>
          <v-text-field
            dense
            hide-details
            outlined
            class="caption"
            placeholder="Cari Nama Rekanan 3PL"
            v-model="search"
            @keyup.native.enter="handleSearch"
          >
            <template v-slot:prepend-inner>
              <v-icon @click="handleSearch">
                mdi-magnify
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-tabs v-model="tabSelected" :vertical="$vuetify.breakpoint.mdAndUp">
      <v-tab class="pl-0">
        <v-icon left>
          mdi-car
        </v-icon>
        TONASE
      </v-tab>
      <v-tab class="pl-3">
        <v-icon left>
          mdi-car-2-plus
        </v-icon>
        KUBIKASI
      </v-tab>
      <v-tab class="pl-0">
        <v-icon left>
          mdi-car-3-plus
        </v-icon>
        KARTON
      </v-tab>
      <v-tab-item v-for="(tab, index) in tabItems" :key="tab">
        <v-card
          flat
          style="height:300px;overflowX:hidden;overflowY:auto"
        >
          <transporter
            :ref="'transporter'+index"
            :search="search"
            :tab="tabSelected"
            :index="index"
            :shipment="shipment"
            :lclType="tab"
            :selected3PL="selected3PL"
            :isFetchData="isFetchData"
            @set3PL="set3PL"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import Transporter from './Transporter.vue';

export default {
  props: {
    isFetchData: {
      type: Boolean,
      default: false,
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    selected3PL: {
      type: [Object, null],
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Transporter,
  },
  watch: {
    isFetchData(newVal) {
      if (!newVal) this.tabSelected = 0;
    },
  },
  data() {
    return {
      search: '',
      tabSelected: 0,
      tabItems: ['TONNAGE', 'CUBIC', 'CARTON'],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.sm;
    },
  },
  methods: {
    handleSearch() {
      this.$refs[`transporter${[this.tabSelected]}`][0].search = this.search;
      this.$refs[`transporter${[this.tabSelected]}`][0].fetchTransporter(true);
    },
    set3PL(item) {
      this.$emit('set3PL', item);
    },
  },
};
</script>
