<template>
  <section ref="container">
    <v-row>
      <v-col>
        <h4 class="pl-2">{{$_strings.order.SHIPPER_INFO}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-1">
        <v-data-table
          calculate-widths
          hide-default-footer
          class="grey lighten-4"
          :headers="displayedHeadersShipperInfo"
          :items="item.subOrder.shipmentResponse || []"
          :items-per-page="100"
        >
          <template v-slot:[`item.orderDate`]={item}>
            <p class="ma-0">{{dateFormat(item.orderDate)}}</p>
            <p>{{timeFormat(item.orderDate)}}</p>
          </template>
          <template v-slot:[`item.senderName`]={item}>
            <span class="ma-0">{{item.originalLocation.picName || '-'}}</span>
          </template>
          <template v-slot:[`item.action`]={item}>
            <v-tooltip v-if="showBtnCancelTheOrder" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  small
                  icon
                  @click="toggleDialog('dialogCancelOrder', item)"
                  :disabled="disableBtnCancelOrder(item)"
                  @mouseover="hoverBtnCancelOrder('cancel', true)"
                  @mouseleave="hoverBtnCancelOrder('cancel', false)"
                >
                  <v-icon>
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.order.CANCEL_ORDER}}</span>
            </v-tooltip>
            <v-tooltip v-if="showBtnCompleteTheOrder" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="toggleDialog('dialogCompleteTheOrder', item)"
                  :disabled="disableBtnCompleteTheOrder(item)"
                >
                  <v-icon>
                    mdi-check-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $_strings.order.COMPLETE_THE_ORDER }}</span>
            </v-tooltip>
            <v-tooltip v-if="showBtnUpdateOrder" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="blue lighten-2"
                  :disabled="disableBtnUpdateStatus(item)"
                  @click="$emit('navigateToPageUpdateOrders', item)"
                >
                  <v-icon>
                    mdi-square-edit-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $_strings.order.UPDATE_STATUS }}</span>
            </v-tooltip>
            <v-tooltip v-if="showBtnResendToOracle" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  small
                  icon
                  :loading="item.isLoadingResendToOracale"
                  @click="handleResendToOracle(item)"
                  :disabled="disableBtnResendToOracle(item.orderStatus)"
                >
                  <v-icon>
                    mdi-rotate-3d-variant
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.order.RESEND_TO_ORACLE}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogCancelOrder
      ref="dialogCancelOrder"
      @cancelOrder="cancelOrder"
    />
    <DialogCompleteTheOrder
      ref="dialogCompleteTheOrder"
      @completeOrder="completeOrder"
    />
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '../../../helper/commonHelpers';
import RenderFunction from './function';
import DialogCancelOrder from '../Dialog/CancelOrder.vue';
import DialogCompleteTheOrder from '../Dialog/CompleteTheOrder.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogCancelOrder,
    DialogCompleteTheOrder,
  },
  data() {
    return {
      orderWaiting: [
        'Pesanan Dibuat',
        'Menunggu Konfirmasi',
      ],
      orderBeforeDelevery: [
        'Terkonfirmasi',
        'Menunggu Konfirmasi',
        'Menunggu Konfirmasi Driver',
        'Menunggu Konfirmasi Transporter',
        'Driver Waktu Habis',
        'Waktu Habis',
        'Penjemputan',
        'Di Lokasi Penjemputan',
      ],
      orderCompleted: [
        'Ditolak',
        'Dibatalkan',
        'Kedaluwarsa',
        'Ba Konfirmasi Shipper',
        'Barang Diterima Utuh',
        'Barang Diterima Tidak Utuh',
        'BA Penerimaan - Diterima Gudang',
        'BA Penerimaan - Kembali Ke Gudang asal',
      ],
    };
  },
  computed: {
    statusPage() {
      const { status } = this.$route.params; // status => unconfirmed, process, finished, confirmation_transporter, confirmation_driver
      return status;
    },
    showBtnResendToOracle() {
      // const showButtonInPage = ['process'];
      // return showButtonInPage.includes(this.statusPage);
      return false;
    },
    showBtnUpdateOrder() {
      const isPageUpdateOrder = this.$route.path.split('/')[2] === 'update-orders';
      const showButtonInPage = ['process'];
      return showButtonInPage.includes(this.statusPage) && isPageUpdateOrder && this.menuFunctions.UPDATE_STATUS_PESANAN;
    },
    showBtnCancelTheOrder() {
      const showButtonInPage = ['unconfirmed', 'process'];
      return showButtonInPage.includes(this.statusPage) && this.menuFunctions.TOLAK_PESANAN;
    },
    showBtnCompleteTheOrder() {
      const showButtonInPage = ['process'];
      return showButtonInPage.includes(this.statusPage) && this.menuFunctions.SELESAIKAN_PESANAN;
    },
    showBtnAction() {
      if (this.statusPage === 'unconfirmed') return this.showBtnCancelTheOrder;
      return this.showBtnUpdateOrder || this.showBtnCompleteTheOrder;
    },
    displayedHeadersShipperInfo() {
      const renderFunction = new RenderFunction(this);
      return renderFunction.tableHeader('shipper').filter((header) => {
        if (header.value === 'action' && !this.showBtnAction) return;
        return header;
      });
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    hoverBtnCancelOrder(button, isHover) {
      if (button === 'cancel' && isHover) {
        this.$refs.container.classList.add('custom_container');
        return;
      }
      this.$refs.container.classList.remove('custom_container');
    },
    toggleDialog(refName, item = {}) {
      this.$refs[refName].order = item;
      this.$refs[refName].dialog = !this.$refs[refName].dialog;
    },
    disableBtnUpdateStatus(item) {
      const { orderNumber } = item;
      const { routes } = this.item.subOrder;
      let isBast = false;
      if (routes && routes.length) {
        routes.forEach((r) => {
          if (r.listShipmentBast && r.listShipmentBast.includes(orderNumber)) isBast = true;
        });
      }
      const statusData = [...this.orderWaiting, 'Dibatalkan', 'Kedaluwarsa'];
      return statusData.includes(item.orderStatus) || isBast;
    },
    disableBtnResendToOracle(status) {
      return !this.orderWaiting.includes(status);
    },
    disableBtnCancelOrder(item) {
      const { cubic, carton, tonnage } = item;
      if (typeof cubic === 'number' || typeof carton === 'number' || typeof tonnage === 'number') return true;
      const canCancelStatus = [
        'pesanan dibuat',
        'menunggu konfirmasi',
        'menunggu konfirmasi driver',
        'menunggu konfirmasi transporter',
        'ditolak',
        'terkonfirmasi',
        'driver waktu habis',
        'waktu habis',
        'di lokasi penjemputan',
        'penjemputan',
      ];
      // IF TRUE RETURN FALSE
      return !canCancelStatus.includes(item.orderStatus.toLowerCase());
    },
    disableBtnCompleteTheOrder(item) {
      return [...this.orderWaiting, ...this.orderBeforeDelevery, 'Ditolak', 'Dibatalkan', 'Kedaluwarsa'].includes(item.orderStatus);
    },
    async handleResendToOracle(item) {
      try {
        this.$set(item, 'isLoadingResendToOracale', true);
      } finally {
        this.$delete(item, 'isLoadingResendToOracale');
      }
    },
    async cancelOrder(id, form) {
      try {
        this.$refs.dialogCancelOrder.isLoading = true;
        await this.$_services.order.cancelShipment(id, form);
        this.$dialog.notify.success('Berhasil membatalkan pesanan');
        this.$refs.dialogCancelOrder.dialog = false;
        this.$emit('fetchOrders');
        this.$emit('isHasWaitingOrder');
      } finally {
        this.$refs.dialogCancelOrder.isLoading = false;
      }
    },
    async completeOrder(id, form) {
      try {
        this.$refs.dialogCompleteTheOrder.isLoading = true;
        await this.$_services.order.completeOrder(id, form);
        this.$emit('fetchOrders');
        this.$dialog.notify.success('Berhasil menyelesaikan pesanan');
        this.$refs.dialogCompleteTheOrder.dialog = false;
      } finally {
        this.$refs.dialogCompleteTheOrder.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .custom_container::v-deep
    .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover {
      background-color: rgba(202, 45, 132, 0.08) !important;
    }
</style>
