var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',{ref:"container",staticClass:"pa-0 mb-5",attrs:{"fluid":""}},[_c('FilterOrder',_vm._b({attrs:{"orderBy":_vm.orderByTitle,"filters":_vm.filters},on:{"fetchOrders":_vm.fetchOrders,"fetching":function($event){return _vm.$emit('fetching', _vm.status)}}},'FilterOrder',_vm.$props,false)),_c('v-sheet',[_c('v-data-table',{staticClass:"body pt-4",attrs:{"item-key":"index","show-expand":"","headers":_vm.displayedHeader,"items":_vm.items,"expanded":_vm.expanded,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page
        },"loading":_vm.isLoading,"loading-text":_vm.$_strings.order.LOADING_TEXT},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"click:row":_vm.viewDetailOrder,"item-expanded":function (ref) {
                var value = ref.value;
                var item = ref.item;

                return value && _vm.fetchSubOrder(item);
}},scopedSlots:_vm._u([{key:"item.serviceType",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.serviceType === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : item.serviceType)+" ")])]}},{key:"item.pickupDate",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.pickupDate)))]),_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.pickupDate)))])]}},{key:"item.transportModel",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.transportModel(item.transportModel)))])]}},{key:"item.latestStatus",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.removeUnderscoreString(item.latestStatus)))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('btn-actions',{attrs:{"item":item,"status":_vm.status},on:{"fetchOrders":_vm.fetchOrders,"refreshDataOnPage":function (_status) { return _vm.$emit('fetching', _status); }}})]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('expanded-item-list',{attrs:{"headers":headers,"item":item,"status":_vm.status,"itemTemporary":_vm.itemTemporary},on:{"fetchOrders":_vm.fetchOrders,"fetchSubOrder":_vm.fetchSubOrder,"isHasWaitingOrder":_vm.isHasWaitingOrder,"fetchNewLegend":_vm.fetchNewLegend,"viewDetailOrder":_vm.viewDetailOrder,"navigateToPageUpdateOrders":_vm.navigateToPageUpdateOrders}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.order.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }